import interceptor from './interceptor'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL
const HIRE_API_URL = process.env.VUE_APP_HIRE_API

// Configuração da API Plooral Hire

export const hireHttp = axios.create({ baseURL: HIRE_API_URL })

hireHttp.interceptors.request.use(async config => {
  let plooralGlobal = localStorage.getItem('plooral-global')
  if (plooralGlobal) {
    plooralGlobal = JSON.parse(plooralGlobal)
    config.headers['x-access-token'] = plooralGlobal?.token
    const path = window.location.pathname + '/'
    if (path.includes('/c/') || path.includes('/p/')) {
      const companyName = path.slice(3, path.slice(3, path.length).indexOf('/') + 3)
      const vuex = JSON.parse(localStorage.getItem('vuex')).User
      if (vuex?.user.companies?.length && companyName) {
        const companies = vuex?.user.companies.filter(c => c.subdomain === companyName)
        if (companies.length) config.headers['x-access-company'] = companies[0].id
      }
    }
    if (config.url === 'v1/-/rss' && !config.headers['x-access-company']) {
      return Promise.reject(new Error())
    }
  }
  return config
})

// Configuração da API Plooral LX

// API Plooral LX - Open
export const openHttp = axios.create({ baseURL: API_URL })

// API Plooral LX - Autenticada
export const http = axios.create({
  baseURL: API_URL
})

http.interceptors.request.use(async config => {
  let plooralGlobal = localStorage.getItem('plooral-global')
  if (plooralGlobal) {
    plooralGlobal = JSON.parse(plooralGlobal)
    config.headers['x-access-token'] = plooralGlobal?.token
  }
  return config
})

export default function install (Vue, { store, router }) {
  interceptor(http, store, router)
  interceptor(hireHttp, store, router)
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return http
    },
    getOpen () {
      return openHttp
    },
    getHire () {
      return hireHttp
    }
  })
}
