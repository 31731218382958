/* eslint-disable */
exports.countryFromCode = function(code) {
  var c = {
    '1': 'us',
    '7': 'ru',
    '20': 'eg',
    '27': 'za',
    '30': 'gr',
    '31': 'nl',
    '32': 'be',
    '33': 'fr',
    '34': 'es',
    '36': 'hu',
    '39': 'va',
    '40': 'ro',
    '41': 'ch',
    '43': 'at',
    '44': 'gb',
    '45': 'dk',
    '46': 'se',
    '47': 'no',
    '48': 'pl',
    '49': 'de',
    '51': 'pe',
    '52': 'mx',
    '53': 'cu',
    '54': 'ar',
    '55': 'br',
    '56': 'cl',
    '57': 'co',
    '58': 've',
    '60': 'my',
    '61': 'au',
    '62': 'id',
    '63': 'ph',
    '64': 'nz',
    '65': 'sg',
    '66': 'th',
    '81': 'jp',
    '82': 'kr',
    '84': 'vn',
    '86': 'cn',
    '90': 'tr',
    '91': 'in',
    '92': 'pk',
    '93': 'af',
    '94': 'lk',
    '95': 'mm',
    '98': 'ir',
    '211': 'ss',
    '212': 'ma',
    '213': 'dz',
    '216': 'tn',
    '218': 'ly',
    '220': 'gm',
    '221': 'sn',
    '222': 'mr',
    '223': 'ml',
    '224': 'gn',
    '225': 'ci',
    '226': 'bf',
    '227': 'ne',
    '228': 'tg',
    '229': 'bj',
    '230': 'mu',
    '231': 'lr',
    '232': 'sl',
    '233': 'gh',
    '234': 'ng',
    '235': 'td',
    '236': 'cf',
    '237': 'cm',
    '238': 'cv',
    '239': 'st',
    '240': 'gq',
    '241': 'ga',
    '242': 'cg',
    '243': 'cd',
    '244': 'ao',
    '245': 'gw',
    '246': 'io',
    '248': 'sc',
    '249': 'sd',
    '250': 'rw',
    '251': 'et',
    '252': 'so',
    '253': 'dj',
    '254': 'ke',
    '255': 'tz',
    '256': 'ug',
    '257': 'bi',
    '258': 'mz',
    '260': 'zm',
    '261': 'mg',
    '262': 're',
    '263': 'zw',
    '264': 'na',
    '265': 'mw',
    '266': 'ls',
    '267': 'bw',
    '268': 'sz',
    '269': 'km',
    '290': 'sh',
    '291': 'er',
    '297': 'aw',
    '298': 'fo',
    '299': 'gl',
    '350': 'gi',
    '351': 'pt',
    '352': 'lu',
    '353': 'ie',
    '354': 'is',
    '355': 'al',
    '356': 'mt',
    '357': 'cy',
    '358': 'fi',
    '359': 'bg',
    '370': 'lt',
    '371': 'lv',
    '372': 'ee',
    '373': 'md',
    '374': 'am',
    '375': 'by',
    '376': 'ad',
    '377': 'mc',
    '378': 'sm',
    '380': 'ua',
    '381': 'rs',
    '382': 'me',
    '385': 'hr',
    '386': 'si',
    '387': 'ba',
    '389': 'mk',
    '420': 'cz',
    '421': 'sk',
    '423': 'li',
    '500': 'fk',
    '501': 'bz',
    '502': 'gt',
    '503': 'sv',
    '504': 'hn',
    '505': 'ni',
    '506': 'cr',
    '507': 'pa',
    '508': 'pm',
    '509': 'ht',
    '590': 'mf',
    '591': 'bo',
    '592': 'gy',
    '593': 'ec',
    '594': 'gf',
    '595': 'py',
    '596': 'mq',
    '597': 'sr',
    '598': 'uy',
    '599': 'cw',
    '670': 'tl',
    '672': 'nf',
    '673': 'bn',
    '674': 'nr',
    '675': 'pg',
    '676': 'to',
    '677': 'sb',
    '678': 'vu',
    '679': 'fj',
    '680': 'pw',
    '681': 'wf',
    '682': 'ck',
    '683': 'nu',
    '685': 'ws',
    '686': 'ki',
    '687': 'nc',
    '688': 'tv',
    '689': 'pf',
    '690': 'tk',
    '691': 'fm',
    '692': 'mh',
    '850': 'kp',
    '852': 'hk',
    '853': 'mo',
    '855': 'kh',
    '856': 'la',
    '880': 'bd',
    '886': 'tw',
    '960': 'mv',
    '961': 'lb',
    '962': 'jo',
    '963': 'sy',
    '964': 'iq',
    '965': 'kw',
    '966': 'sa',
    '967': 'ye',
    '968': 'om',
    '970': 'ps',
    '971': 'ae',
    '972': 'il',
    '973': 'bh',
    '974': 'qa',
    '975': 'bt',
    '976': 'mn',
    '977': 'np',
    '992': 'tj',
    '993': 'tm',
    '994': 'az',
    '995': 'ge',
    '996': 'kg',
    '998': 'uz',
    '1242': 'bs',
    '1246': 'bb',
    '1264': 'ai',
    '1268': 'ag',
    '1284': 'vg',
    '1340': 'vi',
    '1345': 'ky',
    '1441': 'bm',
    '1473': 'gd',
    '1649': 'tc',
    '1664': 'ms',
    '1670': 'mp',
    '1671': 'gu',
    '1684': 'as',
    '1721': 'sx',
    '1758': 'lc',
    '1767': 'dm',
    '1784': 'vc',
    '1868': 'tt',
    '1869': 'kn',
    '1876': 'jm'
  };
  return c[code];
};

exports.getCountries = function(code) {
    var countries = {
      'br': {
        'dialCode': '55',
        'countryCode': 'br',
        'countryName': 'Brazil (Brasil)'
      },
      'us': {
        'dialCode': '1',
        'countryCode': 'us',
        'countryName': 'United States'
      },
      'af': {
        'dialCode': '93',
        'countryCode': 'af',
        'countryName': 'Afghanistan (افغانستان‎)'
      },
      'al': {
        'dialCode': '355',
        'countryCode': 'al',
        'countryName': 'Albania (Shqipëri)'
      },
      'dz': {
        'dialCode': '213',
        'countryCode': 'dz',
        'countryName': 'Algeria (الجزائر‎)'
      },
      'as': {
        'dialCode': '1684',
        'countryCode': 'as',
        'countryName': 'American Samoa'
      },
      'ad': {
        'dialCode': '376',
        'countryCode': 'ad',
        'countryName': 'Andorra'
      },
      'ao': {
        'dialCode': '244',
        'countryCode': 'ao',
        'countryName': 'Angola'
      },
      'ai': {
        'dialCode': '1264',
        'countryCode': 'ai',
        'countryName': 'Anguilla'
      },
      'ag': {
        'dialCode': '1268',
        'countryCode': 'ag',
        'countryName': 'Antigua and Barbuda'
      },
      'ar': {
        'dialCode': '54',
        'countryCode': 'ar',
        'countryName': 'Argentina'
      },
      'am': {
        'dialCode': '374',
        'countryCode': 'am',
        'countryName': 'Armenia (Հայաստան)'
      },
      'aw': {
        'dialCode': '297',
        'countryCode': 'aw',
        'countryName': 'Aruba'
      },
      'au': {
        'dialCode': '61',
        'countryCode': 'au',
        'countryName': 'Australia'
      },
      'at': {
        'dialCode': '43',
        'countryCode': 'at',
        'countryName': 'Austria (Österreich)'
      },
      'az': {
        'dialCode': '994',
        'countryCode': 'az',
        'countryName': 'Azerbaijan (Azərbaycan)'
      },
      'bs': {
        'dialCode': '1242',
        'countryCode': 'bs',
        'countryName': 'Bahamas'
      },
      'bh': {
        'dialCode': '973',
        'countryCode': 'bh',
        'countryName': 'Bahrain (البحرين‎)'
      },
      'bd': {
        'dialCode': '880',
        'countryCode': 'bd',
        'countryName': 'Bangladesh (বাংলাদেশ)'
      },
      'bb': {
        'dialCode': '1246',
        'countryCode': 'bb',
        'countryName': 'Barbados'
      },
      'by': {
        'dialCode': '375',
        'countryCode': 'by',
        'countryName': 'Belarus (Беларусь)'
      },
      'be': {
        'dialCode': '32',
        'countryCode': 'be',
        'countryName': 'Belgium (België)'
      },
      'bz': {
        'dialCode': '501',
        'countryCode': 'bz',
        'countryName': 'Belize'
      },
      'bj': {
        'dialCode': '229',
        'countryCode': 'bj',
        'countryName': 'Benin (Bénin)'
      },
      'bm': {
        'dialCode': '1441',
        'countryCode': 'bm',
        'countryName': 'Bermuda'
      },
      'bt': {
        'dialCode': '975',
        'countryCode': 'bt',
        'countryName': 'Bhutan (འབྲུག)'
      },
      'bo': {
        'dialCode': '591',
        'countryCode': 'bo',
        'countryName': 'Bolivia'
      },
      'ba': {
        'dialCode': '387',
        'countryCode': 'ba',
        'countryName': 'Bosnia and Herzegovina (Босна и Херцеговина)'
      },
      'bw': {
        'dialCode': '267',
        'countryCode': 'bw',
        'countryName': 'Botswana'
      },
      'io': {
        'dialCode': '246',
        'countryCode': 'io',
        'countryName': 'British Indian Ocean Territory'
      },
      'vg': {
        'dialCode': '1284',
        'countryCode': 'vg',
        'countryName': 'British Virgin Islands'
      },
      'bn': {
        'dialCode': '673',
        'countryCode': 'bn',
        'countryName': 'Brunei'
      },
      'bg': {
        'dialCode': '359',
        'countryCode': 'bg',
        'countryName': 'Bulgaria (България)'
      },
      'bf': {
        'dialCode': '226',
        'countryCode': 'bf',
        'countryName': 'Burkina Faso'
      },
      'bi': {
        'dialCode': '257',
        'countryCode': 'bi',
        'countryName': 'Burundi (Uburundi)'
      },
      'kh': {
        'dialCode': '855',
        'countryCode': 'kh',
        'countryName': 'Cambodia (កម្ពុជា)'
      },
      'cm': {
        'dialCode': '237',
        'countryCode': 'cm',
        'countryName': 'Cameroon (Cameroun)'
      },
      'ca': {
        'dialCode': '1',
        'countryCode': 'ca',
        'countryName': 'Canada'
      },
      'cv': {
        'dialCode': '238',
        'countryCode': 'cv',
        'countryName': 'Cape Verde (Kabu Verdi)'
      },
      'bq': {
        'dialCode': '599',
        'countryCode': 'bq',
        'countryName': 'Caribbean Netherlands'
      },
      'ky': {
        'dialCode': '1345',
        'countryCode': 'ky',
        'countryName': 'Cayman Islands'
      },
      'cf': {
        'dialCode': '236',
        'countryCode': 'cf',
        'countryName': 'Central African Republic (République centrafricaine)'
      },
      'td': {
        'dialCode': '235',
        'countryCode': 'td',
        'countryName': 'Chad (Tchad)'
      },
      'cl': {
        'dialCode': '56',
        'countryCode': 'cl',
        'countryName': 'Chile'
      },
      'cn': {
        'dialCode': '86',
        'countryCode': 'cn',
        'countryName': 'China (中国)'
      },
      'co': {
        'dialCode': '57',
        'countryCode': 'co',
        'countryName': 'Colombia'
      },
      'km': {
        'dialCode': '269',
        'countryCode': 'km',
        'countryName': 'Comoros (جزر القمر‎)'
      },
      'cd': {
        'dialCode': '243',
        'countryCode': 'cd',
        'countryName': 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)'
      },
      'cg': {
        'dialCode': '242',
        'countryCode': 'cg',
        'countryName': 'Congo (Republic) (Congo-Brazzaville)'
      },
      'ck': {
        'dialCode': '682',
        'countryCode': 'ck',
        'countryName': 'Cook Islands'
      },
      'cr': {
        'dialCode': '506',
        'countryCode': 'cr',
        'countryName': 'Costa Rica'
      },
      'ci': {
        'dialCode': '225',
        'countryCode': 'ci',
        'countryName': 'Côte d’Ivoire'
      },
      'hr': {
        'dialCode': '385',
        'countryCode': 'hr',
        'countryName': 'Croatia (Hrvatska)'
      },
      'cu': {
        'dialCode': '53',
        'countryCode': 'cu',
        'countryName': 'Cuba'
      },
      'cw': {
        'dialCode': '599',
        'countryCode': 'cw',
        'countryName': 'Curaçao'
      },
      'cy': {
        'dialCode': '357',
        'countryCode': 'cy',
        'countryName': 'Cyprus (Κύπρος)'
      },
      'cz': {
        'dialCode': '420',
        'countryCode': 'cz',
        'countryName': 'Czech Republic (Česká republika)'
      },
      'dk': {
        'dialCode': '45',
        'countryCode': 'dk',
        'countryName': 'Denmark (Danmark)'
      },
      'dj': {
        'dialCode': '253',
        'countryCode': 'dj',
        'countryName': 'Djibouti'
      },
      'dm': {
        'dialCode': '1767',
        'countryCode': 'dm',
        'countryName': 'Dominica'
      },
      'do': {
        'dialCode': '1',
        'countryCode': 'do',
        'countryName': 'Dominican Republic (República Dominicana)'
      },
      'ec': {
        'dialCode': '593',
        'countryCode': 'ec',
        'countryName': 'Ecuador'
      },
      'eg': {
        'dialCode': '20',
        'countryCode': 'eg',
        'countryName': 'Egypt (مصر‎)'
      },
      'sv': {
        'dialCode': '503',
        'countryCode': 'sv',
        'countryName': 'El Salvador'
      },
      'gq': {
        'dialCode': '240',
        'countryCode': 'gq',
        'countryName': 'Equatorial Guinea (Guinea Ecuatorial)'
      },
      'er': {
        'dialCode': '291',
        'countryCode': 'er',
        'countryName': 'Eritrea'
      },
      'ee': {
        'dialCode': '372',
        'countryCode': 'ee',
        'countryName': 'Estonia (Eesti)'
      },
      'et': {
        'dialCode': '251',
        'countryCode': 'et',
        'countryName': 'Ethiopia'
      },
      'fk': {
        'dialCode': '500',
        'countryCode': 'fk',
        'countryName': 'Falkland Islands (Islas Malvinas)'
      },
      'fo': {
        'dialCode': '298',
        'countryCode': 'fo',
        'countryName': 'Faroe Islands (Føroyar)'
      },
      'fj': {
        'dialCode': '679',
        'countryCode': 'fj',
        'countryName': 'Fiji'
      },
      'fi': {
        'dialCode': '358',
        'countryCode': 'fi',
        'countryName': 'Finland (Suomi)'
      },
      'fr': {
        'dialCode': '33',
        'countryCode': 'fr',
        'countryName': 'France'
      },
      'gf': {
        'dialCode': '594',
        'countryCode': 'gf',
        'countryName': 'French Guiana (Guyane française)'
      },
      'pf': {
        'dialCode': '689',
        'countryCode': 'pf',
        'countryName': 'French Polynesia (Polynésie française)'
      },
      'ga': {
        'dialCode': '241',
        'countryCode': 'ga',
        'countryName': 'Gabon'
      },
      'gm': {
        'dialCode': '220',
        'countryCode': 'gm',
        'countryName': 'Gambia'
      },
      'ge': {
        'dialCode': '995',
        'countryCode': 'ge',
        'countryName': 'Georgia (საქართველო)'
      },
      'de': {
        'dialCode': '49',
        'countryCode': 'de',
        'countryName': 'Germany (Deutschland)'
      },
      'gh': {
        'dialCode': '233',
        'countryCode': 'gh',
        'countryName': 'Ghana (Gaana)'
      },
      'gi': {
        'dialCode': '350',
        'countryCode': 'gi',
        'countryName': 'Gibraltar'
      },
      'gr': {
        'dialCode': '30',
        'countryCode': 'gr',
        'countryName': 'Greece (Ελλάδα)'
      },
      'gl': {
        'dialCode': '299',
        'countryCode': 'gl',
        'countryName': 'Greenland (Kalaallit Nunaat)'
      },
      'gd': {
        'dialCode': '1473',
        'countryCode': 'gd',
        'countryName': 'Grenada'
      },
      'gp': {
        'dialCode': '590',
        'countryCode': 'gp',
        'countryName': 'Guadeloupe'
      },
      'gu': {
        'dialCode': '1671',
        'countryCode': 'gu',
        'countryName': 'Guam'
      },
      'gt': {
        'dialCode': '502',
        'countryCode': 'gt',
        'countryName': 'Guatemala'
      },
      'gn': {
        'dialCode': '224',
        'countryCode': 'gn',
        'countryName': 'Guinea (Guinée)'
      },
      'gw': {
        'dialCode': '245',
        'countryCode': 'gw',
        'countryName': 'Guinea-Bissau (Guiné Bissau)'
      },
      'gy': {
        'dialCode': '592',
        'countryCode': 'gy',
        'countryName': 'Guyana'
      },
      'ht': {
        'dialCode': '509',
        'countryCode': 'ht',
        'countryName': 'Haiti'
      },
      'hn': {
        'dialCode': '504',
        'countryCode': 'hn',
        'countryName': 'Honduras'
      },
      'hk': {
        'dialCode': '852',
        'countryCode': 'hk',
        'countryName': 'Hong Kong (香港)'
      },
      'hu': {
        'dialCode': '36',
        'countryCode': 'hu',
        'countryName': 'Hungary (Magyarország)'
      },
      'is': {
        'dialCode': '354',
        'countryCode': 'is',
        'countryName': 'Iceland (Ísland)'
      },
      'in': {
        'dialCode': '91',
        'countryCode': 'in',
        'countryName': 'India (भारत)'
      },
      'id': {
        'dialCode': '62',
        'countryCode': 'id',
        'countryName': 'Indonesia'
      },
      'ir': {
        'dialCode': '98',
        'countryCode': 'ir',
        'countryName': 'Iran (ایران‎)'
      },
      'iq': {
        'dialCode': '964',
        'countryCode': 'iq',
        'countryName': 'Iraq (العراق‎)'
      },
      'ie': {
        'dialCode': '353',
        'countryCode': 'ie',
        'countryName': 'Ireland'
      },
      'il': {
        'dialCode': '972',
        'countryCode': 'il',
        'countryName': 'Israel (ישראל‎)'
      },
      'it': {
        'dialCode': '39',
        'countryCode': 'it',
        'countryName': 'Italy (Italia)'
      },
      'jm': {
        'dialCode': '1876',
        'countryCode': 'jm',
        'countryName': 'Jamaica'
      },
      'jp': {
        'dialCode': '81',
        'countryCode': 'jp',
        'countryName': 'Japan (日本)'
      },
      'jo': {
        'dialCode': '962',
        'countryCode': 'jo',
        'countryName': 'Jordan (الأردن‎)'
      },
      'kz': {
        'dialCode': '7',
        'countryCode': 'kz',
        'countryName': 'Kazakhstan (Казахстан)'
      },
      'ke': {
        'dialCode': '254',
        'countryCode': 'ke',
        'countryName': 'Kenya'
      },
      'ki': {
        'dialCode': '686',
        'countryCode': 'ki',
        'countryName': 'Kiribati'
      },
      'kw': {
        'dialCode': '965',
        'countryCode': 'kw',
        'countryName': 'Kuwait (الكويت‎)'
      },
      'kg': {
        'dialCode': '996',
        'countryCode': 'kg',
        'countryName': 'Kyrgyzstan (Кыргызстан)'
      },
      'la': {
        'dialCode': '856',
        'countryCode': 'la',
        'countryName': 'Laos (ລາວ)'
      },
      'lv': {
        'dialCode': '371',
        'countryCode': 'lv',
        'countryName': 'Latvia (Latvija)'
      },
      'lb': {
        'dialCode': '961',
        'countryCode': 'lb',
        'countryName': 'Lebanon (لبنان‎)'
      },
      'ls': {
        'dialCode': '266',
        'countryCode': 'ls',
        'countryName': 'Lesotho'
      },
      'lr': {
        'dialCode': '231',
        'countryCode': 'lr',
        'countryName': 'Liberia'
      },
      'ly': {
        'dialCode': '218',
        'countryCode': 'ly',
        'countryName': 'Libya (ليبيا‎)'
      },
      'li': {
        'dialCode': '423',
        'countryCode': 'li',
        'countryName': 'Liechtenstein'
      },
      'lt': {
        'dialCode': '370',
        'countryCode': 'lt',
        'countryName': 'Lithuania (Lietuva)'
      },
      'lu': {
        'dialCode': '352',
        'countryCode': 'lu',
        'countryName': 'Luxembourg'
      },
      'mo': {
        'dialCode': '853',
        'countryCode': 'mo',
        'countryName': 'Macau (澳門)'
      },
      'mk': {
        'dialCode': '389',
        'countryCode': 'mk',
        'countryName': 'Macedonia (FYROM) (Македонија)'
      },
      'mg': {
        'dialCode': '261',
        'countryCode': 'mg',
        'countryName': 'Madagascar (Madagasikara)'
      },
      'mw': {
        'dialCode': '265',
        'countryCode': 'mw',
        'countryName': 'Malawi'
      },
      'my': {
        'dialCode': '60',
        'countryCode': 'my',
        'countryName': 'Malaysia'
      },
      'mv': {
        'dialCode': '960',
        'countryCode': 'mv',
        'countryName': 'Maldives'
      },
      'ml': {
        'dialCode': '223',
        'countryCode': 'ml',
        'countryName': 'Mali'
      },
      'mt': {
        'dialCode': '356',
        'countryCode': 'mt',
        'countryName': 'Malta'
      },
      'mh': {
        'dialCode': '692',
        'countryCode': 'mh',
        'countryName': 'Marshall Islands'
      },
      'mq': {
        'dialCode': '596',
        'countryCode': 'mq',
        'countryName': 'Martinique'
      },
      'mr': {
        'dialCode': '222',
        'countryCode': 'mr',
        'countryName': 'Mauritania (موريتانيا‎)'
      },
      'mu': {
        'dialCode': '230',
        'countryCode': 'mu',
        'countryName': 'Mauritius (Moris)'
      },
      'mx': {
        'dialCode': '52',
        'countryCode': 'mx',
        'countryName': 'Mexico (México)'
      },
      'fm': {
        'dialCode': '691',
        'countryCode': 'fm',
        'countryName': 'Micronesia'
      },
      'md': {
        'dialCode': '373',
        'countryCode': 'md',
        'countryName': 'Moldova (Republica Moldova)'
      },
      'mc': {
        'dialCode': '377',
        'countryCode': 'mc',
        'countryName': 'Monaco'
      },
      'mn': {
        'dialCode': '976',
        'countryCode': 'mn',
        'countryName': 'Mongolia (Монгол)'
      },
      'me': {
        'dialCode': '382',
        'countryCode': 'me',
        'countryName': 'Montenegro (Crna Gora)'
      },
      'ms': {
        'dialCode': '1664',
        'countryCode': 'ms',
        'countryName': 'Montserrat'
      },
      'ma': {
        'dialCode': '212',
        'countryCode': 'ma',
        'countryName': 'Morocco (المغرب‎)'
      },
      'mz': {
        'dialCode': '258',
        'countryCode': 'mz',
        'countryName': 'Mozambique (Moçambique)'
      },
      'mm': {
        'dialCode': '95',
        'countryCode': 'mm',
        'countryName': 'Myanmar (Burma) (မြန်မာ)'
      },
      'na': {
        'dialCode': '264',
        'countryCode': 'na',
        'countryName': 'Namibia (Namibië)'
      },
      'nr': {
        'dialCode': '674',
        'countryCode': 'nr',
        'countryName': 'Nauru'
      },
      'np': {
        'dialCode': '977',
        'countryCode': 'np',
        'countryName': 'Nepal (नेपाल)'
      },
      'nl': {
        'dialCode': '31',
        'countryCode': 'nl',
        'countryName': 'Netherlands (Nederland)'
      },
      'nc': {
        'dialCode': '687',
        'countryCode': 'nc',
        'countryName': 'New Caledonia (Nouvelle-Calédonie)'
      },
      'nz': {
        'dialCode': '64',
        'countryCode': 'nz',
        'countryName': 'New Zealand'
      },
      'ni': {
        'dialCode': '505',
        'countryCode': 'ni',
        'countryName': 'Nicaragua'
      },
      'ne': {
        'dialCode': '227',
        'countryCode': 'ne',
        'countryName': 'Niger (Nijar)'
      },
      'ng': {
        'dialCode': '234',
        'countryCode': 'ng',
        'countryName': 'Nigeria'
      },
      'nu': {
        'dialCode': '683',
        'countryCode': 'nu',
        'countryName': 'Niue'
      },
      'nf': {
        'dialCode': '672',
        'countryCode': 'nf',
        'countryName': 'Norfolk Island'
      },
      'kp': {
        'dialCode': '850',
        'countryCode': 'kp',
        'countryName': 'North Korea (조선 민주주의 인민 공화국)'
      },
      'mp': {
        'dialCode': '1670',
        'countryCode': 'mp',
        'countryName': 'Northern Mariana Islands'
      },
      'no': {
        'dialCode': '47',
        'countryCode': 'no',
        'countryName': 'Norway (Norge)'
      },
      'om': {
        'dialCode': '968',
        'countryCode': 'om',
        'countryName': 'Oman (عُمان‎)'
      },
      'pk': {
        'dialCode': '92',
        'countryCode': 'pk',
        'countryName': 'Pakistan (پاکستان‎)'
      },
      'pw': {
        'dialCode': '680',
        'countryCode': 'pw',
        'countryName': 'Palau'
      },
      'ps': {
        'dialCode': '970',
        'countryCode': 'ps',
        'countryName': 'Palestine (فلسطين‎)'
      },
      'pa': {
        'dialCode': '507',
        'countryCode': 'pa',
        'countryName': 'Panama (Panamá)'
      },
      'pg': {
        'dialCode': '675',
        'countryCode': 'pg',
        'countryName': 'Papua New Guinea'
      },
      'py': {
        'dialCode': '595',
        'countryCode': 'py',
        'countryName': 'Paraguay'
      },
      'pe': {
        'dialCode': '51',
        'countryCode': 'pe',
        'countryName': 'Peru (Perú)'
      },
      'ph': {
        'dialCode': '63',
        'countryCode': 'ph',
        'countryName': 'Philippines'
      },
      'pl': {
        'dialCode': '48',
        'countryCode': 'pl',
        'countryName': 'Poland (Polska)'
      },
      'pt': {
        'dialCode': '351',
        'countryCode': 'pt',
        'countryName': 'Portugal'
      },
      'pr': {
        'dialCode': '1',
        'countryCode': 'pr',
        'countryName': 'Puerto Rico'
      },
      'qa': {
        'dialCode': '974',
        'countryCode': 'qa',
        'countryName': 'Qatar (قطر‎)'
      },
      're': {
        'dialCode': '262',
        'countryCode': 're',
        'countryName': 'Réunion (La Réunion)'
      },
      'ro': {
        'dialCode': '40',
        'countryCode': 'ro',
        'countryName': 'Romania (România)'
      },
      'ru': {
        'dialCode': '7',
        'countryCode': 'ru',
        'countryName': 'Russia (Россия)'
      },
      'rw': {
        'dialCode': '250',
        'countryCode': 'rw',
        'countryName': 'Rwanda'
      },
      'bl': {
        'dialCode': '590',
        'countryCode': 'bl',
        'countryName': 'Saint Barthélemy (Saint-Barthélemy)'
      },
      'sh': {
        'dialCode': '290',
        'countryCode': 'sh',
        'countryName': 'Saint Helena'
      },
      'kn': {
        'dialCode': '1869',
        'countryCode': 'kn',
        'countryName': 'Saint Kitts and Nevis'
      },
      'lc': {
        'dialCode': '1758',
        'countryCode': 'lc',
        'countryName': 'Saint Lucia'
      },
      'mf': {
        'dialCode': '590',
        'countryCode': 'mf',
        'countryName': 'Saint Martin (Saint-Martin (partie française))'
      },
      'pm': {
        'dialCode': '508',
        'countryCode': 'pm',
        'countryName': 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)'
      },
      'vc': {
        'dialCode': '1784',
        'countryCode': 'vc',
        'countryName': 'Saint Vincent and the Grenadines'
      },
      'ws': {
        'dialCode': '685',
        'countryCode': 'ws',
        'countryName': 'Samoa'
      },
      'sm': {
        'dialCode': '378',
        'countryCode': 'sm',
        'countryName': 'San Marino'
      },
      'st': {
        'dialCode': '239',
        'countryCode': 'st',
        'countryName': 'São Tomé and Príncipe (São Tomé e Príncipe)'
      },
      'sa': {
        'dialCode': '966',
        'countryCode': 'sa',
        'countryName': 'Saudi Arabia (المملكة العربية السعودية‎)'
      },
      'sn': {
        'dialCode': '221',
        'countryCode': 'sn',
        'countryName': 'Senegal (Sénégal)'
      },
      'rs': {
        'dialCode': '381',
        'countryCode': 'rs',
        'countryName': 'Serbia (Србија)'
      },
      'sc': {
        'dialCode': '248',
        'countryCode': 'sc',
        'countryName': 'Seychelles'
      },
      'sl': {
        'dialCode': '232',
        'countryCode': 'sl',
        'countryName': 'Sierra Leone'
      },
      'sg': {
        'dialCode': '65',
        'countryCode': 'sg',
        'countryName': 'Singapore'
      },
      'sx': {
        'dialCode': '1721',
        'countryCode': 'sx',
        'countryName': 'Sint Maarten'
      },
      'sk': {
        'dialCode': '421',
        'countryCode': 'sk',
        'countryName': 'Slovakia (Slovensko)'
      },
      'si': {
        'dialCode': '386',
        'countryCode': 'si',
        'countryName': 'Slovenia (Slovenija)'
      },
      'sb': {
        'dialCode': '677',
        'countryCode': 'sb',
        'countryName': 'Solomon Islands'
      },
      'so': {
        'dialCode': '252',
        'countryCode': 'so',
        'countryName': 'Somalia (Soomaaliya)'
      },
      'za': {
        'dialCode': '27',
        'countryCode': 'za',
        'countryName': 'South Africa'
      },
      'kr': {
        'dialCode': '82',
        'countryCode': 'kr',
        'countryName': 'South Korea (대한민국)'
      },
      'ss': {
        'dialCode': '211',
        'countryCode': 'ss',
        'countryName': 'South Sudan (جنوب السودان‎)'
      },
      'es': {
        'dialCode': '34',
        'countryCode': 'es',
        'countryName': 'Spain (España)'
      },
      'lk': {
        'dialCode': '94',
        'countryCode': 'lk',
        'countryName': 'Sri Lanka (ශ්‍රී ලංකාව)'
      },
      'sd': {
        'dialCode': '249',
        'countryCode': 'sd',
        'countryName': 'Sudan (السودان‎)'
      },
      'sr': {
        'dialCode': '597',
        'countryCode': 'sr',
        'countryName': 'Suriname'
      },
      'sz': {
        'dialCode': '268',
        'countryCode': 'sz',
        'countryName': 'Swaziland'
      },
      'se': {
        'dialCode': '46',
        'countryCode': 'se',
        'countryName': 'Sweden (Sverige)'
      },
      'ch': {
        'dialCode': '41',
        'countryCode': 'ch',
        'countryName': 'Switzerland (Schweiz)'
      },
      'sy': {
        'dialCode': '963',
        'countryCode': 'sy',
        'countryName': 'Syria (سوريا‎)'
      },
      'tw': {
        'dialCode': '886',
        'countryCode': 'tw',
        'countryName': 'Taiwan (台灣)'
      },
      'tj': {
        'dialCode': '992',
        'countryCode': 'tj',
        'countryName': 'Tajikistan'
      },
      'tz': {
        'dialCode': '255',
        'countryCode': 'tz',
        'countryName': 'Tanzania'
      },
      'th': {
        'dialCode': '66',
        'countryCode': 'th',
        'countryName': 'Thailand (ไทย)'
      },
      'tl': {
        'dialCode': '670',
        'countryCode': 'tl',
        'countryName': 'Timor-Leste'
      },
      'tg': {
        'dialCode': '228',
        'countryCode': 'tg',
        'countryName': 'Togo'
      },
      'tk': {
        'dialCode': '690',
        'countryCode': 'tk',
        'countryName': 'Tokelau'
      },
      'to': {
        'dialCode': '676',
        'countryCode': 'to',
        'countryName': 'Tonga'
      },
      'tt': {
        'dialCode': '1868',
        'countryCode': 'tt',
        'countryName': 'Trinidad and Tobago'
      },
      'tn': {
        'dialCode': '216',
        'countryCode': 'tn',
        'countryName': 'Tunisia (تونس‎)'
      },
      'tr': {
        'dialCode': '90',
        'countryCode': 'tr',
        'countryName': 'Turkey (Türkiye)'
      },
      'tm': {
        'dialCode': '993',
        'countryCode': 'tm',
        'countryName': 'Turkmenistan'
      },
      'tc': {
        'dialCode': '1649',
        'countryCode': 'tc',
        'countryName': 'Turks and Caicos Islands'
      },
      'tv': {
        'dialCode': '688',
        'countryCode': 'tv',
        'countryName': 'Tuvalu'
      },
      'vi': {
        'dialCode': '1340',
        'countryCode': 'vi',
        'countryName': 'U.S. Virgin Islands'
      },
      'ug': {
        'dialCode': '256',
        'countryCode': 'ug',
        'countryName': 'Uganda'
      },
      'ua': {
        'dialCode': '380',
        'countryCode': 'ua',
        'countryName': 'Ukraine (Україна)'
      },
      'ae': {
        'dialCode': '971',
        'countryCode': 'ae',
        'countryName': 'United Arab Emirates (الإمارات العربية المتحدة‎)'
      },
      'gb': {
        'dialCode': '44',
        'countryCode': 'gb',
        'countryName': 'United Kingdom'
      },
      'uy': {
        'dialCode': '598',
        'countryCode': 'uy',
        'countryName': 'Uruguay'
      },
      'uz': {
        'dialCode': '998',
        'countryCode': 'uz',
        'countryName': 'Uzbekistan (Oʻzbekiston)'
      },
      'vu': {
        'dialCode': '678',
        'countryCode': 'vu',
        'countryName': 'Vanuatu'
      },
      'va': {
        'dialCode': '39',
        'countryCode': 'va',
        'countryName': 'Vatican City (Città del Vaticano)'
      },
      've': {
        'dialCode': '58',
        'countryCode': 've',
        'countryName': 'Venezuela'
      },
      'vn': {
        'dialCode': '84',
        'countryCode': 'vn',
        'countryName': 'Vietnam (Việt Nam)'
      },
      'wf': {
        'dialCode': '681',
        'countryCode': 'wf',
        'countryName': 'Wallis and Futuna'
      },
      'ye': {
        'dialCode': '967',
        'countryCode': 'ye',
        'countryName': 'Yemen (اليمن‎)'
      },
      'zm': {
        'dialCode': '260',
        'countryCode': 'zm',
        'countryName': 'Zambia'
      },
      'zw': {
        'dialCode': '263',
        'countryCode': 'zw',
        'countryName': 'Zimbabwe'
      }
    };
    if (code) return countries[code];
    return countries
}