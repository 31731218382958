export default {
  state: {
    feedbackMsg: null,
    lastRoute: null,
    helpMsg: null,
    showSpinner: false,
    showAppBanner: true
  },
  getters: {
    getHelpMsg: (state) => state.helpMsg,
    getFeedbackMsg: (state) => state.feedbackMsg,
    getLastRoute: (state) => state.lastRoute,
    getShowSpinner: (state) => state.showSpinner,
    getShowAppBanner: (state) => state.showAppBanner
  },
  mutations: {
    setFeedbackMsg (state, value) {
      state.feedbackMsg = value
    },
    setHelpMsg (state, value) {
      state.helpMsg = value
    },
    setLastRoute (state, value) {
      state.lastRoute = value
    },
    setShowSpinner (state, value) {
      state.showSpinner = value
    },
    setShowAppBanner (state, value) {
      state.showAppBanner = value
    }
  },
  actions: {
    attemptSetHelpMsg ({ commit }, helpMsg) {
      commit('setHelpMsg', helpMsg)
    },
    attemptSetFeedbackMsg ({ commit }, feedbackMsg) {
      commit('setFeedbackMsg', feedbackMsg)
    },
    attemptSetShowSpinner ({ commit }, showSpinner) {
      commit('setShowSpinner', showSpinner)
    }
  }
}
