export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/NewHome'),
    redirect: { name: 'workspace.selection.index' },
    children: [
      {
        path: '/home',
        name: 'home.user',
        component: () => import('@/components/home/HomepageUser'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'home.user.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home'
            }
          },
          {
            path: 'content/details/:contentId',
            name: 'home.user.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'home.user'
            },
            children: [
              {
                path: 'list-association',
                name: 'home.user.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'home.user.content.details'
                }
              }
            ]
          },
          {
            path: 'list/manage/:listId?',
            name: 'home.user.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'home.user'
            }
          },
          {
            path: 'list/details/:listId',
            name: 'home.user.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'home.user'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'home.user.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'home.user.lists.details'
                },
                children: [
                  {
                    path: 'list-association',
                    name: 'home.user.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'home.user.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage',
                name: 'home.user.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'home.user'
                }
              }
            ]
          },
          {
            path: ':contentId/list-association',
            name: 'home.user.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'home.user'
            }
          }
        ]
      },
      {
        path: '/favorites',
        name: 'home.user.favorites',
        component: () => import('@/components/home/HomepageUserFavorites'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'home.user.favorites.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home'
            }
          },
          {
            path: 'content/details/:contentId',
            name: 'home.user.favorites.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'home.user.favorites'
            },
            children: [
              {
                path: 'list-association',
                name: 'home.user.favorites.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'home.user.favorites.content.details'
                }
              }
            ]
          },
          {
            path: 'list/manage/:listId?',
            name: 'home.user.favorites.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'home.user.favorites'
            }
          },
          {
            path: 'list/details/:listId',
            name: 'home.user.favorites.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'home.user.favorites'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'home.user.favorites.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'home.user.favorites.lists.details'
                },
                children: [
                  {
                    path: 'list-association',
                    name: 'home.user.favorites.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'home.user.favorites.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage',
                name: 'home.user.favorites.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'home.user.favorites'
                }
              }
            ]
          },
          {
            path: ':contentId/list-association',
            name: 'home.user.favorites.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'home.user.favorites'
            }
          }
        ]
      },
      {
        path: '/contents',
        name: 'home.user.contents',
        component: () => import('@/components/home/HomepageUserContents'),
        children: [
          {
            path: 'manage/:contentId?',
            name: 'home.user.contents.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home.user.contents'
            }
          },
          {
            path: 'details/:contentId',
            name: 'home.user.contents.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'home.user.contents'
            },
            children: [
              {
                path: 'list-association',
                name: 'home.user.contents.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'home.user.contents.content.details'
                }
              }
            ]
          },
          {
            path: ':contentId/list-association',
            name: 'home.user.contents.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'home.user.contents'
            }
          }
        ]
      },
      {
        path: '/lists',
        name: 'home.user.lists',
        component: () => import('@/components/home/HomepageUserLists'),
        children: [
          {
            path: 'manage/:listId?',
            name: 'home.user.lists.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'home.user.lists'
            }
          },
          {
            path: 'details/:listId',
            name: 'home.user.lists.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'home.user.lists'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'home.user.lists.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'home.user.lists.lists.details'
                },
                children: [
                  {
                    path: 'list-association',
                    name: 'home.user.lists.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'home.user.lists.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage',
                name: 'home.user.lists.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'home.user.lists'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/members',
        name: 'home.user.members',
        component: () => import('@/components/home/HomepageUserMembers'),
        children: [
          {
            path: '/members/list',
            name: 'home.user.members.list',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabMember')
          },
          {
            path: '/members/invites',
            name: 'home.user.members.invites',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabInvite')
          }
        ]
      },
      {
        path: '/customizations',
        name: 'home.user.customization',
        component: () => import('@/components/home/HomepageUserCustomization')
      },
      {
        path: '/management',
        name: 'home.user.management',
        component: () => import('@/components/home/HomepageUserManagement')
      },
      {
        path: '/applications',
        name: 'home.user.applications',
        component: () => import('@/components/home/HomepageUserApplications'),
        children: [
          {
            path: 'pending',
            name: 'home.user.applications.pending',
            component: () => import('@/components/profile/PendingApplications')
          },
          {
            path: 'analysis',
            name: 'home.user.applications.under.analysis',
            component: () => import('@/components/profile/UnderAnalysisApplications')
          },
          {
            path: 'expired',
            name: 'home.user.applications.expired',
            component: () => import('@/components/profile/OldApplications')
          }
        ]
      }
    ]
  },
  {
    path: '/invite/:inviteId',
    name: 'invite.check',
    component: () => import('@/views/InviteCheck'),
    meta: {
      hideMenu: true
    }
  }
]
