export default [
  {
    path: '/register',
    name: 'auth.register',
    component: () => import('@/components/authentication/registration/RegistrationPage'),
    meta: {
      hideMenu: true,
      public: true,
      registerRoute: true
    }
  },
  {
    path: '/register/:type',
    name: 'register',
    meta: {
      public: true,
      preventRoute: true
    },
    component: () => import('@/views/RegisterRedirect')
  },
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('@/components/authentication/login/LoginPage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/recovery',
    name: 'auth.recovery',
    component: () => import('@/components/authentication/login/RecoveryPage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/access/changepassword/:language/:code',
    name: 'auth.new.password',
    component: () => import('@/components/authentication/login/NewPasswordPage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/invite',
    name: 'auth.invite',
    component: () => import('@/components/authentication/registration/ValidationInvitePage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/invite/login',
    name: 'auth.invite.login',
    component: () => import('@/components/authentication/login/InviteLoginPage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/prospect/invite/:hash',
    name: 'prospectInvite',
    component: () => import('@/views/Prospecting/ProspectInviteManage'),
    meta: {
      hideMenu: true,
      public: true
    }
  },
  {
    path: '/prospect/invite/decline/:hash',
    name: 'prospectInviteDecline',
    component: () => import('@/views/Prospecting/ProspectInviteDecline'),
    meta: {
      hideMenu: true,
      public: true
    }
  }
]
