<script>
export default {
  components: { },
  name: 'ModalShowMessage',
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>
<template>
  <v-dialog :value="true" persistent no-click-animation width="780px" max-width="100%">
    <v-card class="modal-show-message--container">
      <div class="modal-show-message--header">
        <h2 class="modal-show-message--title">{{ $t(title) }}</h2>
        <v-icon class="clickable" @click="$emit('close')">mdi-close</v-icon>
      </div>
      <div class="modal-show-message--body">
        <span v-html="text">{{ text }}</span>
      </div>
      <div class="modal-show-message--bottom">
        <v-btn class="btn transform-unset" :color="getPrimaryColor" dark @click="$emit('close')">{{ $t('global:ok') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-show-message--container {
  .modal-show-message--header {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: space-between;
    .modal-show-message--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
    }
  }
  .modal-show-message--body {
    padding: 24px;
  }
  .modal-show-message--bottom {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: flex-end;
  }
 }
</style>
