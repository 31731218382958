import { http } from '@/support/axios'
import { createQuery, parseToFormDataWithArray } from '@/support/payloadParser'

export default {
  state: {
    workspacesMembers: [],
    enterpriseWorkspace: {},
    individualWorkspace: {},
    currentContentFilters: {},
    workspacesContents: [],
    workspacesLists: [],
    workspaceTos: [],
    workspaceInfos: [],
    userWorkspaces: [],
    selectedWorkspace: null,
    theme: null,
    wantToChangeWorkspace: null
  },
  getters: {
    getWorkspaceMembers: (state) => state.workspacesMembers,
    getEnterpriseWorkspace: (state) => state.enterpriseWorkspace,
    getIndividualWorkspace: (state) => state.individualWorkspace,
    getCurrentContentFilters: (state) => state.currentContentFilters,
    getWorkspaceContent: (state) => state.workspacesContents,
    getWorkspaceLists: (state) => state.workspacesLists,
    getWorkspaceInfos: (state) => state.workspaceInfos,
    getUserWorkspaces: (state) => state.userWorkspaces,
    getSelectedWorkspace: (state) => state.selectedWorkspace,
    getThemeWorkspace: (state) => state.theme,
    getWantToChangeWorkspace: (state) => state.wantToChangeWorkspace
  },
  mutations: {
    updateWantedWorkspaceChange (state, workspace) {
      if (state && workspace) state.wantToChangeWorkspace = workspace
    },
    updateWorkspaceMembers (state, payload) {
      state.workspacesMembers[payload.workspaceId] = payload.value
      state.workspacesMembers = { ...state.workspacesMembers }
    },
    updateWorkspaceInfo (state, payload) {
      state.workspaceInfos[payload.workspaceId] = payload.value
      state.workspaceInfos = { ...state.workspaceInfos }

      if (state.individualWorkspace.id === payload.workspaceId) {
        state.individualWorkspace = { ...state.individualWorkspace, ...payload.value }
      }
      if (state.enterpriseWorkspace.id === payload.workspaceId) {
        state.enterpriseWorkspace = payload.value
      }
    },
    updateEnterpriseWorkspace (state, value) {
      state.enterpriseWorkspace = { ...state.enterpriseWorkspace, ...value }
    },
    updateIndividualWorkspace (state, value) {
      state.individualWorkspace = { ...state.individualWorkspace, ...value }
    },
    updateCurrentContentFilters (state, value) {
      state.currentContentFilters = value
    },
    updateWorkspaceContents (state, payload) {
      state.workspacesContents[payload.workspaceId] = payload.value
      state.workspacesContents = { ...state.workspacesContents }
    },
    updateWorkspaceLists (state, payload) {
      state.workspacesLists[payload.workspaceId] = payload.value
      state.workspacesLists = { ...state.workspacesLists }
    },
    updateWorkspaceTos (state, payload) {
      const usrWorkspace = state.userWorkspaces.filter(work => work.subdomain === payload.subdomain)
      state.selectedWorkspace = usrWorkspace[0]
      state.selectedWorkspace.userTosAcceptedAt = new Date()
      state.selectedWorkspace = { ...state.selectedWorkspace }
      usrWorkspace.userTosAcceptedAt = payload.tos
      state.userWorkspaces = [...state.userWorkspaces]
    },
    setDeletedWorkspace (state, workspaceId) {
      state.workspaceInfos[workspaceId] = { ...state.workspaceInfos[workspaceId], deletionRequest: { status: 'PENDING' } }
    },
    setActiveWorkspace (state, workspaceId) {
      state.userWorkspaces.map(workspace => {
        if (workspace.id === workspaceId) {
          workspace.deletionRequest = { status: 'CANCELED' }
        }
      })
      state.userWorkspaces = [...state.userWorkspaces]
      state.workspaceInfos[workspaceId] = { ...state.workspaceInfos[workspaceId], deletionRequest: { status: 'CANCELED' } }
    },
    updateUserWorkspaces (state, { workspaces, force = false }) {
      if (!state.userWorkspaces.length || force) {
        state.userWorkspaces = workspaces
      } else {
        workspaces.forEach((workspace, i) => {
          const index = state.userWorkspaces.findIndex(find => {
            return find.id === workspace.id || find.id === workspace.idpCompanyId || find.name === workspace.name
          })

          if (index !== -1) {
            state.userWorkspaces[index] = { lxID: workspace.id, ...workspace, ...state.userWorkspaces[index] }
          } else if (workspace.type === 'aggregator') {
            state.userWorkspaces.push(workspace)
          }
        })
      }
    },
    updateUserIndividualWorkspaces (state, individualWorkspace) {
      const index = state.userWorkspaces.findIndex(workspace => workspace.type === 'individual')
      state.userWorkspaces[index] = { ...state.userWorkspaces[index], ...individualWorkspace }
      state.userWorkspaces = [...state.userWorkspaces]
    },
    setSelectedWorkspace (state, workspace) {
      state.selectedWorkspace = workspace
    },
    updateSelectedWorkspaceInvites (state, payload) {
      state.selectedWorkspace.invites = payload || []
      state.selectedWorkspace = { ...state.selectedWorkspace }
    },
    updateSetThemeWorkspace (state, theme) {
      state.theme = theme
    }
  },
  actions: {
    attemptGetWorkspaceMembers ({ commit }, { workspaceId, ...queryString }) {
      const query = createQuery(queryString)
      return http.get(`v1/workspaces/${workspaceId}/users?${query}`)
        .then(({ data }) => {
          commit('updateWorkspaceMembers', { workspaceId, value: data.data })
          return data
        })
    },
    attemptUpdateCurrentContentFilters ({ commit }, data) {
      commit('updateCurrentContentFilters', data)
    },
    attemptSaveWorkspaceSettings ({ commit }, { id, payload }) {
      const parsedFormData = parseToFormDataWithArray(payload)
      if (payload.imageUrl && typeof payload.imageUrl === 'object') {
        parsedFormData.append('image', payload.imageUrl, payload.imageUrl.name)
      }
      if (payload.coverImage && typeof payload.coverImage === 'object') {
        parsedFormData.append('coverImage', payload.coverImage, payload.coverImage.name)
      }
      return http.post('v1/workspaces/' + id, parsedFormData).then(({ data }) => {
        commit('updateWorkspaceInfo', { workspaceId: id, value: data })
      })
    },
    attemptInviteWorkspaceMember ({ commit }, { workspaceId, invites }) {
      return http.post(`v1/workspaces/${workspaceId}/invites`, invites)
    },
    attemptResendInviteWorkspaceMember ({ commit }, inviteId) {
      return http.patch(`v1/workspaces/invites/${inviteId}/resend`)
    },
    attemptGetUserWorkspace ({ commit }, type) {
      return http.get(`v1/myself/workspaces?type=${type}`).then(({ data }) => {
        type === 'individual'
          ? commit('updateIndividualWorkspace', data[data.length - 1])
          : commit('updateEnterpriseWorkspace', data[0])
        return data
      })
    },
    attemptUpdateIndividualWorkspace ({ commit }, data) {
      commit('updateIndividualWorkspace', data)
      commit('updateUserIndividualWorkspaces', data)
    },
    attemptGetWorkspaceInvites ({ commit }, { workspaceId, filter }) {
      const query = createQuery({ filter: filter })
      return http.get(`v1/workspaces/${workspaceId}/invites?${query}`).then(({ data }) => {
        commit('updateSelectedWorkspaceInvites', data.data)
        return data
      })
    },
    attemptGetUserWorkspaces ({ commit }, skip) {
      const availableAggregators = (localStorage.getItem('availableAggregators') && JSON.parse(localStorage.getItem('availableAggregators'))) || []
      if (skip) {
        commit('updateUserWorkspaces', { workspaces: [...availableAggregators] })
        return [...availableAggregators]
      }
      return http.get('v1/myself/workspaces').then(({ data }) => {
        commit('updateUserWorkspaces', { workspaces: [...data, ...availableAggregators] })
        return [...data, ...availableAggregators]
      })
    },
    attemptUpdateUserWorkspaces ({ commit }, workspaces) {
      commit('updateUserWorkspaces', { workspaces: workspaces })
    },
    attemptSetWorkspaceValue ({ commit }, value) {
      commit('updateEnterpriseWorkspace', value)
    },
    attemptGetUserWorkspaceById (_, userId) {
      return http.get(`v1/workspaces/user/${userId}`)
    },
    attemptGetWorkspaceById ({ commit }, workspaceId) {
      return http.get(`v1/workspaces/${workspaceId}`).then((response) => {
        commit('updateWorkspaceInfo', { workspaceId, value: response.data })
        return response
      })
    },
    attemptGetWorkspaceContents ({ commit }, { workspaceId, filter, limit, page }) {
      const query = createQuery({ filter: filter, limit: limit || 6, page: page || 1 })
      return http.get(`v1/contents/workspace/${workspaceId}?${query}`)
        .then(({ data }) => {
          commit('updateWorkspaceContents', { workspaceId, value: data.data })
          return data
        }).catch(() => {
          return { error: true }
        })
    },
    attemptGetWorkspaceLists ({ commit }, { workspaceId, filter = {} }) {
      const query = createQuery({ filter: filter })
      return http.get(`v1/lists/workspace/${workspaceId}?${query}`)
        .then(({ data }) => {
          commit('updateWorkspaceLists', { workspaceId, value: data })
        }).catch(() => {
          return { error: true }
        })
    },
    attemptGetPeopleSuggestions (_, { workspaceId, filter }) {
      const query = createQuery(filter)
      return http.get(`v1/workspaces/${workspaceId}/users/suggestions?${query}`)
    },
    attemptDeleteWorkspace ({ commit }, workspaceId) {
      return http.delete(`v1/workspaces/${workspaceId}`).then(() => {
        commit('setDeletedWorkspace', workspaceId)
      })
    },
    attemptReactivateWorkspace ({ commit }, workspaceId) {
      return http.patch(`v1/workspaces/${workspaceId}/reactivate`).then(() => {
        commit('setActiveWorkspace', workspaceId)
      })
    },
    attemptGetWorkspaceOwnerById ({ commit }, { workspaceId, ownerId }) {
      http.get(`v1/contents/workspace/${workspaceId}`)
        .then(({ data }) => {
          commit('updateWorkspaceContents', { workspaceId, value: data })
        })
    },
    attemptGetLocationByName (_, name) {
      return http.get(`v1/location?location=${name}`)
    },
    attemptCheckInviteStatus (_, inviteId) {
      return http.get(`v1/workspaces/invites/${inviteId}`)
    },
    attemptAcceptWorkspaceInvite (_, inviteId) {
      return http.patch(`v1/workspaces/invites/${inviteId}/complete`)
    },
    attemptRefuseWorkspaceInvite (_, inviteId) {
      return http.patch(`v1/workspaces/invites/${inviteId}/refuse`)
    },
    attemptChangeMemberProfile (_, payload) {
      return http.patch(`v1/workspaces/${payload.workspaceId}/users/${payload.userId}`, payload.body)
    },
    attemptChangeMemberProfileByEmail (_, payload) {
      return http.patch(`v1/workspaces/${payload.workspaceId}/users`, payload)
    },
    attemptRemoveUserFromWorkspace (_, payload) {
      return http.delete(`v1/workspaces/${payload.workspaceId}/users/${payload.userId}`)
    }
  }
}
