import Vue from 'vue'
import VueRouter from 'vue-router'

const requireModule = require.context('.', false, /\.router\.js$/)
const modules = []

requireModule.keys().forEach(filename => {
  filename
    .replace(/(\.\/|\.router\.js)/g, '')
    .replace(/^\w/, c => c.toUpperCase())
  modules.push(...requireModule(filename).default)
})

Vue.use(VueRouter)

const routes = [
  ...modules,
  {
    path: '/c/:companyDomain/*',
    redirect: { name: 'business.home' }
  },
  {
    path: '/p/:companyDomain/*',
    redirect: { name: 'aggregator.home' }
  },
  {
    path: '*',
    redirect: { name: 'workspace.selection.index' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const plooralGlobal = localStorage.getItem('plooral-global')
  const openRoutes = ['auth.invite', 'prospectInvite', 'prospectInviteDecline']
  if (openRoutes.includes(to.name) || (to.meta.public && !plooralGlobal) || (!to.meta.public && plooralGlobal)) {
    next()
  } else if (plooralGlobal && to.meta.public) {
    next({ path: '/' })
  } else {
    const query = {}
    if (to.name.split('.')[0] === 'aggregator') {
      query.theme = to.params.companyDomain
    }
    if (to.query.theme) query.theme = to.params.companyDomain
    const getUri = decodeURIComponent(location.href.replace(`${location.protocol}//${location.host}/`, ''))
    const uri = getUri === 'login' ? '' : getUri
    if (uri) query.redirect = uri
    next({ name: 'auth.login', query })
  }
})

export default router
