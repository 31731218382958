import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptbr from './pt-BR'
import config from '@/config'
import { MAIN_SET_LANGUAGE } from '../../store/types'

Vue.use(VueI18n)

const { DEFAULT_LANGUAGE } = config
const fallbackLocale = 'pt-BR'
const currentLocale = localStorage.getItem('vuex') && JSON.parse(localStorage.getItem('vuex')).language
const loadedLanguages = ['pt-BR']
const messages = {
  'pt-BR': ptbr
}

const dateTimeConfig = {
  shortTime: {
    hour: 'numeric',
    minute: 'numeric'
  },
  shortDayMonth: {
    day: 'numeric',
    month: 'numeric'
  },
  shortDayMonthTime: {
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  short: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  },
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  },
  longSeconds: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
}

const dateTimeFormats = {
  'pt-BR': dateTimeConfig,
  en: dateTimeConfig
}

function setI18nLanguage (i18n, lang) {
  i18n.locale = lang
  // eslint-disable-next-line no-undef
  zE('webWidget', 'setLocale', lang)
  document.documentElement.lang = lang
}

export function loadLanguageAsync (i18n, lang) {
  lang = lang.indexOf('pt') === 0 ? 'pt-BR' : 'en'

  if (!loadedLanguages.includes(lang)) {
    return import(/* webpackChunkName: "lang-[request]" */ './' + (lang || fallbackLocale)).then(msgs => {
      i18n.setLocaleMessage(lang, msgs.default)
      loadedLanguages.push(lang)
      setI18nLanguage(i18n, lang)
    })
  }
  return Promise.resolve(setI18nLanguage(i18n, lang))
}

function buildVuexI18nPlugin (i18n) {
  return store => {
    store.subscribe(mutation => {
      if (mutation.type === MAIN_SET_LANGUAGE) {
        loadLanguageAsync(i18n, mutation.payload).then(() => {
          store.commit('setLanguageIsLoaded', true)
        })
      }
    })
  }
}

const i18n = new VueI18n({
  locale: fallbackLocale,
  fallbackLocale: fallbackLocale,
  messages,
  dateTimeFormats,
  missing: (locale, key, vm) => {
    console.warn(`Missing translation: ${key} \nLocale: ${locale} \nComponent: ${vm.$options.name}`)
  }
})

loadLanguageAsync(i18n, currentLocale || navigator.language)

if (DEFAULT_LANGUAGE !== fallbackLocale) {
  loadLanguageAsync(i18n, DEFAULT_LANGUAGE)
}

export const vuexI18nPlugin = buildVuexI18nPlugin(i18n)

export default i18n
