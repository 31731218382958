import { http, hireHttp } from '@/support/axios'
import { createQuery } from '@/support/payloadParser'

export default {
  state: {
    availableSkills: [],
    lastFiveSkillsUsed: [],
    pendingUsedSkills: []
  },
  getters: {
    getAvailableSkills: (state) => state.availableSkills,
    getLastFiveSkillsUsed: (state) => state.lastFiveSkillsUsed
  },
  mutations: {
    updateAvailableSkills (state, value) {
      state.availableSkills = value
    },
    updateLastFiveSkillsUsed (state, skills = []) {
      state.lastFiveSkillsUsed = [...state.lastFiveSkillsUsed, ...state.pendingUsedSkills, ...skills].slice(-5)
      state.pendingUsedSkills = []
    },
    pushRecentSkill (state, skill) {
      const alreadyListed = state.pendingUsedSkills.findIndex(listed => listed.id === skill.id) !== -1
      const alreadyUsed = state.lastFiveSkillsUsed.findIndex(listed => listed.id === skill.id) !== -1

      if (!alreadyListed && !alreadyUsed) {
        state.pendingUsedSkills = [...state.pendingUsedSkills, skill].slice(-5)
      }
    },
    removeRecentSkill (state, skill) {
      state.pendingUsedSkills = state.pendingUsedSkills.filter(actual => actual.id !== skill.id)
    },
    clearRecentSkill (state) {
      state.pendingUsedSkills = []
    }
  },
  actions: {
    attemptGetAvailableSkills ({ commit }, pagination = {}) {
      const query = createQuery({ order: 'DESC', limit: (pagination && pagination.limit) || 20 })
      return http.get(`v1/skills/language?${query}${pagination.filterByName ? `&filterByName=${pagination.filterByName}` : ''}`).then(({ data }) => {
        commit('updateAvailableSkills', data.data)
      }).catch(() => {
        return { error: true }
      })
    },
    attemptSaveUserSkills ({ commit }, userSkills) {
      const payload = { skills: userSkills.map((skill) => ({ ...skill, skillId: skill.id || skill.skillId })) }
      return http.post('v1/skills/user', payload)
        .then(response => {
          commit('updateUserSkills', payload.skills)
          commit('updateUser', {})
          return response
        })
    },
    attemptSaveNewSkill (_, skillName) {
      return http.post('v1/skills', { title: skillName.trim() })
    },
    attemptGetSkillsByUser (_, userId) {
      return http.get(`v1/skills/user/${userId}`).then(({ data }) => {
        return data
      })
    },
    attemptGetSkills (_, query) {
      return hireHttp.get(`v1/skills?query=${query}`)
    }
  }
}
