/* eslint-disable no-undef */
import { hireHttp } from '@/support/axios'

const initialState = () => {
  return {
    applications: [],
    oldApplications: []
  }
}

export default {
  state: {
    ...initialState()
  },
  getters: {
    getUserApplications: (state) => state.applications,
    getUserOldApplications: (state) => state.oldApplications
  },
  mutations: {
    clearApplications (state) {
      state = { ...state, ...initialState() }
    },
    setUserApplications (state, applications) {
      state.applications = applications
    },
    setUserOldApplications (state, applications) {
      state.oldApplications = applications
    }
  },
  actions: {
    attemptGetUserApplications ({ commit }) {
      return hireHttp.get('v1/matches/?messagesFrom=2015-01-01T01:00:00.858Z').then((response) => {
        commit('setUserApplications', response.data)
        return response.data
      })
    },
    attemptGetUserOldApplications ({ commit }) {
      return hireHttp.get('v1/oldmatches/?messagesFrom=2015-01-01T01:00:00.858Z').then((response) => {
        commit('setUserOldApplications', response.data)
        return response.data
      })
    }
  }
}
