import { http } from '@/support/axios'
import { parseToFormDataWithArray } from '@/support/payloadParser'

export default {
  state: {
    actualStep: 0,
    isInProgress: null,
    workspacePayload: {
      name: null,
      profileImage: {
        blob: null,
        url: null,
        shouldRemove: null
      },
      coverImage: {
        blob: null,
        url: null,
        shouldRemove: null
      },
      isCompleted: null
    },
    profileAssetsUrls: [],
    coverAssetsUrls: []
  },
  getters: {
    getWorkspaceSetupStep: (state) => state.actualStep,
    getWorkspacePayload: (state) => state.workspacePayload,
    getProfileAssetsUrls: ({ profileAssetsUrls }) => profileAssetsUrls,
    getWorkspaceSetupIsInProgress: ({ isInProgress }) => isInProgress,
    getCoverAssetsUrls: ({ coverAssetsUrls }) => coverAssetsUrls
  },
  mutations: {
    updateActualStep (state, step) {
      state.actualStep = step
    },
    updateWorkspaceName (state, name) {
      state.workspacePayload.name = name
    },
    updateWorkspaceImage (state, image) {
      state.workspacePayload.profileImage = { ...state.workspacePayload.profileImage, ...image }
    },
    updateWorkspaceCoverImage (state, image) {
      state.workspacePayload.coverImage = { ...state.workspacePayload.coverImage, ...image }
    },
    updateProfileAssetsUrls (state, payload) {
      state.profileAssetsUrls = payload
    },
    updateWorkspacePayload (state, payload) {
      state.workspacePayload = payload
    },
    updateWorkspaceSetupIsInProgress (state, payload) {
      state.isInProgress = payload
    },
    updateCoverAssetsUrls (state, payload) {
      state.coverAssetsUrls = payload
    }
  },
  actions: {
    attemptUpdateActualStep ({ commit }, step) {
      commit('updateActualStep', step)
    },
    attemptUpdateWorkspaceName ({ commit }, name) {
      commit('updateWorkspaceName', name)
    },
    attemptUpdateWorkspaceImage ({ commit }, image) {
      commit('updateWorkspaceImage', image)
    },
    attemptUpdateWorkspaceCoverImage ({ commit }, image) {
      commit('updateWorkspaceCoverImage', image)
    },
    attemptUpdateWorkspacePayload ({ commit }, payload) {
      commit('updateWorkspacePayload', payload)
    },
    attemptUpdateWorkspaceSetupIsInProgress ({ commit }, payload) {
      commit('updateWorkspaceSetupIsInProgress', payload)
    },
    attemptUpdateUserEnterpriseWorkspace ({ commit }, { workspaceId, data }) {
      const payload = parseToFormDataWithArray({})

      if (data.name) {
        payload.append('name', data.name)
      }

      if (data.profileImage.url) {
        payload.append('imageUrl', data.profileImage.url)
      }

      if (data.profileImage.blob) {
        payload.append('image', data.profileImage.blob)
      }

      if (data.profileImage.shouldRemove) {
        payload.append('removeImage', true)
      }

      if (data.coverImage.url) {
        payload.append('coverImageUrl', data.coverImage.url)
      }

      if (data.coverImage.blob) {
        payload.append('coverImage', data.coverImage.blob)
      }

      if (data.coverImage.shouldRemove) {
        payload.append('removeCoverImage', true)
      }

      if (data.isCompleted) {
        payload.append('isCompleted', true)
      }

      return http.post(`v1/workspaces/${workspaceId}`, payload)
        .then(({ data }) => {
          commit('updateWorkspaceImage', { url: data.imageUrl, blob: null, shouldRemove: null })
          commit('updateWorkspaceCoverImage', { url: data.coverImageUrl, blob: null, shouldRemove: null })
          return data
        })
    },
    attemptUpdateProfileAssetsUrls ({ commit }) {
      return http.get('v1/assets?prefix=workspace-setup/profile-samples')
        .then(({ data }) => {
          commit('updateProfileAssetsUrls', data)
          return data
        })
    },
    attemptUpdateCoverAssetsUrls ({ commit }) {
      return http.get('v1/assets?prefix=workspace-setup/cover-samples')
        .then(({ data }) => {
          commit('updateCoverAssetsUrls', data)
          return data
        })
    }
  }
}
