export default [
  {
    path: '/c/:companyDomain',
    name: 'business.home',
    component: () => import('@/views/NewHome'),
    redirect: { name: 'business.home.user' },
    children: [
      {
        path: '/c/:companyDomain',
        name: 'business.home.user',
        component: () => import('@/components/home/index/homepage-business'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'business.home.user.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home'
            }
          },
          {
            path: 'content/:contentId',
            name: 'business.home.user.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'business.home.user'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'business.home.user.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.home.user.content.details'
                }
              }
            ]
          },
          {
            path: 'contents/pathways/:listId?/manage',
            name: 'business.home.user.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'business.home.user'
            }
          },
          {
            path: 'contents/pathways/:listId',
            name: 'business.home.user.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'business.home.user'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'business.home.user.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.home.user.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.home.user.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.home.user.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'business.home.user.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'business.home.user'
                }
              }
            ]
          },
          {
            path: ':contentId/pathway-association',
            name: 'business.home.user.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'business.home.user'
            }
          }
        ]
      },
      {
        path: '/c/:companyDomain/contents',
        name: 'business.home.user.contents',
        redirect: { name: 'business.contents.published' },
        component: () => import('@/views/Contents/BusinessContents/BusinessContents'),
        children: [
          {
            path: 'published',
            name: 'business.contents.published',
            component: () => import('@/components/home/HomepageUserContents'),
            children: [
              {
                path: 'manage/:contentId?',
                name: 'business.contents.published.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'business.contents.published'
                }
              },
              {
                path: 'details/:contentId',
                name: 'business.contents.published.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.contents.published'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.contents.published.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.contents.published.content.details'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'business.contents.published.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.contents.published'
                }
              }
            ]
          },
          {
            path: 'lists',
            name: 'business.contents.lists',
            component: () => import('@/components/home/HomepageUserLists'),
            children: [
              {
                path: 'manage/:listId?',
                name: 'business.contents.lists.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'business.contents.lists'
                }
              },
              {
                path: 'details/:listId',
                name: 'business.contents.lists.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'business.contents.lists'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.contents.lists.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.contents.lists.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'business.contents.lists.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'business.contents.lists.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'business.contents.lists.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'business.contents.lists.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'manage/:listId?',
                    name: 'business.contents.lists.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'business.contents.lists'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'favorites',
            name: 'business.user.contents.favorites',
            component: () => import('@/components/home/HomepageUserFavorites'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'business.user.contents.favorites.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'business.user.contents.favorites'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'business.user.contents.favorites.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.user.contents.favorites'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.favorites.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.favorites.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'business.user.contents.favorites.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'business.user.contents.favorites'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'business.user.contents.favorites.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'business.user.contents.favorites'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.favorites.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.favorites.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'business.user.contents.favorites.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'business.user.contents.favorites.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'business.user.contents.favorites.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'business.user.contents.favorites.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'business.user.contents.favorites.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'business.user.contents.favorites'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'business.user.contents.favorites.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.user.contents.favorites'
                }
              }
            ]
          },
          {
            path: 'in-progress',
            name: 'business.user.contents.in.progress',
            component: () => import('@/views/Contents/IndividualContents/IndividualInProgressContents'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'business.user.contents.in.progress.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'home'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'business.user.contents.in.progress.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.user.contents.in.progress'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.in.progress.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.in.progress.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'business.user.contents.in.progress.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'business.user.contents.in.progress'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'business.user.contents.in.progress.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'business.user.contents.in.progress'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.in.progress.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.in.progress.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'business.user.contents.in.progress.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'business.user.contents.in.progress.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'business.user.contents.in.progress.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'business.user.contents.in.progress.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'business.user.contents.in.progress.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'business.user.contents.in.progress'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'business.user.contents.in.progress.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.user.contents.in.progress'
                }
              }
            ]
          },
          {
            path: 'finished',
            name: 'business.user.contents.finished',
            component: () => import('@/views/Contents/IndividualContents/IndividualFinishedContents'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'business.user.contents.finished.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'home'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'business.user.contents.finished.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.user.contents.finished'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.finished.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.finished.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'business.user.contents.finished.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'business.user.contents.finished'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'business.user.contents.finished.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'business.user.contents.finished'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'business.user.contents.finished.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.user.contents.finished.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'business.user.contents.finished.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'business.user.contents.finished.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'business.user.contents.finished.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'business.user.contents.finished.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'business.user.contents.finished.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'business.user.contents.finished'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'business.user.contents.finished.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.user.contents.finished'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/members',
        name: 'business.home.user.members',
        component: () => import('@/components/home/HomepageUserMembers'),
        children: [
          {
            path: '/members/list',
            name: 'business.home.user.members.list',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabMember')
          },
          {
            path: '/members/invites',
            name: 'business.home.user.members.invites',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabInvite')
          }
        ]
      },
      {
        path: 'customizations',
        name: 'business.home.user.customization',
        component: () => import('@/components/home/HomepageUserCustomization')
      },
      {
        path: 'management',
        name: 'business.home.user.management',
        component: () => import('@/components/home/HomepageUserManagement')
      }
    ]
  },
  {
    path: '/c/:companyDomain/applications',
    name: 'business.candidature.index',
    component: () => import('@/views/Candidature')
  },
  {
    path: '/c/:companyDomain/applications/closed',
    name: 'business.candidature.closed',
    component: () => import('@/views/Candidature/ClosedCandidatures')
  },
  {
    path: '/c/:companyDomain/career',
    name: 'business.home.user.career',
    component: () => import('@/views/EmptyRouterView')
  },
  {
    path: '/c/:companyDomain/discovery/position/:positionId',
    name: 'business.position.details',
    component: () => import('@/components/positions/PositionDetails'),
    meta: {
      backRouteName: 'business.discovery.index'
    }
  },
  {
    name: 'business.discovery.index',
    path: '/c/:companyDomain/discovery',
    component: () => import('@/views/BusinessDiscovery'),
    children: [
      {
        path: 'contents/:contentId',
        name: 'business.discovery.index.content.details',
        component: () => import('@/components/contents/ModalContentDetails'),
        meta: {
          backRouteName: 'business.discovery.index'
        },
        children: [
          {
            path: 'pathway-association',
            name: 'business.discovery.index.content.details.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'business.discovery.index.content.details'
            }
          }
        ]
      },
      {
        path: 'contents/:contentId?/manage',
        name: 'business.discovery.index.content.manage',
        component: () => import('@/components/contents/ModalManageContent'),
        meta: {
          backRouteName: 'business.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId?/manage',
        name: 'business.discovery.index.lists.manage',
        component: () => import('@/components/lists/ModalManageList'),
        meta: {
          backRouteName: 'business.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId',
        name: 'business.discovery.index.lists.details',
        component: () => import('@/components/lists/ModalListDetails'),
        meta: {
          backRouteName: 'business.discovery.index'
        },
        children: [
          {
            path: ':contentId',
            name: 'business.discovery.index.lists.details.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'business.discovery.index.lists.details'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'business.discovery.index.lists.details.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.discovery.index.lists.details.content.details'
                }
              }
            ]
          },
          {
            path: 'manage',
            name: 'business.discovery.index.lists.details.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'business.discovery.index'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/c/:companyDomain/internal-mobility',
    name: 'business.internal.positions',
    redirect: { name: 'business.internal.positions.under.analysis' },
    component: () => import('@/views/InternalMobility/InternalMobility'),
    children: [
      {
        path: 'under-analysis',
        name: 'business.internal.positions.under.analysis',
        component: () => import('@/views/InternalMobility/UnderAnalysis')
      },
      {
        path: 'incompleted',
        name: 'business.internal.positions.incompleted',
        component: () => import('@/views/InternalMobility/IncompletedCandidature')
      },
      {
        path: 'finished',
        name: 'business.internal.positions.finished',
        component: () => import('@/views/InternalMobility/FinishedCandidature')
      }
    ]
  },
  {
    path: '/c/:companyDomain/reports',
    name: 'business.reports',
    component: () => import('@/views/EmptyRouterView')
  },
  {
    path: '/c/:companyDomain/admin-center',
    name: 'business.admin.center.index',
    component: () => import('@/views/AdminCenter/AdminCenter')
  },
  {
    path: '/c/:companyDomain/admin-center/company',
    name: 'business.admin.center.company.data',
    component: () => import('@/views/AdminCenter/Company/Company')
  },
  {
    path: '/c/:companyDomain/admin-center/users',
    name: 'business.admin.center.users',
    redirect: { name: 'business.admin.center.users.active' },
    component: () => import('@/views/AdminCenter/Users/Users'),
    children: [
      {
        path: '',
        name: 'business.admin.center.users.active',
        component: () => import('@/views/AdminCenter/Users/ActiveUsers')
      },
      {
        path: 'removed',
        name: 'business.admin.center.users.removed',
        component: () => import('@/views/AdminCenter/Users/RemovedUsers')
      },
      {
        path: 'invites',
        name: 'business.admin.center.users.invites',
        component: () => import('@/views/AdminCenter/Users/ActiveInvites')
      }
    ]
  },
  {
    path: '/c/:companyDomain/admin-center/users/:userId',
    name: 'business.admin.center.users.profile',
    component: () => import('@/views/AdminCenter/Users/UserProfile')
  },
  {
    path: '/c/:companyDomain/admin-center/bookmarks',
    name: 'business.admin.center.bookmarks',
    component: () => import('@/views/AdminCenter/Bookmarks/Bookmarks')
  },
  {
    path: '/c/:companyDomain/admin-center/bookmarks/new',
    name: 'business.admin.center.bookmarks.new',
    component: () => import('@/views/AdminCenter/Bookmarks/EditBookmark')
  },
  {
    path: '/c/:companyDomain/admin-center/bookmarks/:id?',
    name: 'business.admin.center.bookmarks.manage',
    component: () => import('@/views/AdminCenter/Bookmarks/EditBookmark')
  },
  {
    path: '/c/:companyDomain/admin-center/departments',
    name: 'business.admin.center.departments',
    redirect: { name: 'business.admin.center.departments.active' },
    component: () => import('@/views/AdminCenter/Departments/Departments'),
    children: [
      {
        path: 'active',
        name: 'business.admin.center.departments.active',
        component: () => import('@/views/AdminCenter/Departments/ActiveDepartments'),
        children: [
          {
            path: 'new',
            name: 'business.admin.center.departments.new',
            component: () => import('@/views/AdminCenter/Departments/EditDepartment')
          },
          {
            path: ':id',
            name: 'business.admin.center.departments.linked.positions',
            redirect: { name: 'business.admin.center.departments.linked.positions.active' },
            component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositions'),
            children: [
              {
                path: 'active',
                name: 'business.admin.center.departments.linked.positions.active',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsActive')
              },
              {
                path: 'archived',
                name: 'business.admin.center.departments.linked.positions.archived',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsArchived')
              },
              {
                path: 'draft',
                name: 'business.admin.center.departments.linked.positions.draft',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsDraft')
              }
            ]
          }
        ]
      },
      {
        path: 'removed',
        name: 'business.admin.center.departments.removed',
        component: () => import('@/views/AdminCenter/Departments/RemovedDepartments'),
        children: [
          {
            path: ':id',
            name: 'business.admin.center.departments.linked.positions.removed',
            redirect: { name: 'business.admin.center.departments.linked.positions.removed.active' },
            component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositions'),
            children: [
              {
                path: 'active',
                name: 'business.admin.center.departments.linked.positions.removed.active',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsActive')
              },
              {
                path: 'archived',
                name: 'business.admin.center.departments.linked.positions.removed.archived',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsArchived')
              },
              {
                path: 'draft',
                name: 'business.admin.center.departments.linked.positions.removed.draft',
                component: () => import('@/views/AdminCenter/Departments/ModalLinkedPositionsDraft')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/c/:companyDomain/admin-center/advertising-integrations',
    name: 'business.admin.center.advertising.integrations',
    redirect: { name: 'business.admin.center.advertising.integrations.ads' },
    component: () => import('@/views/AdminCenter/AdvertisingIntegrations/AdvertisingIntegrations'),
    children: [
      {
        path: '',
        name: 'business.admin.center.advertising.integrations.ads',
        component: () => import('@/views/AdminCenter/AdvertisingIntegrations/Advertising')
      },
      {
        path: 'integrations',
        name: 'business.admin.center.advertising.integrations.integrations',
        component: () => import('@/views/AdminCenter/AdvertisingIntegrations/Integrations')
      }
    ]
  },
  {
    path: '/c/:companyDomain/admin-center/support',
    name: 'business.admin.center.support',
    component: () => import('@/views/AdminCenter/Support/Support')
  },
  {
    path: '/c/:companyDomain/admin-center/payment',
    name: 'business.admin.center.payment',
    redirect: { name: 'business.admin.center.payment.credits' },
    component: () => import('@/views/AdminCenter/Payment/Payment'),
    children: [
      {
        path: 'credits',
        name: 'business.admin.center.payment.credits',
        component: () => import('@/views/AdminCenter/Payment/CreditsHistory.vue')
      },
      {
        path: 'other-services',
        name: 'business.admin.center.payment.other.services',
        component: () => import('@/views/AdminCenter/Payment/OtherServices.vue')
      }
    ]
  },
  {
    path: '/c/:companyDomain/admin-center/payment/add-credits',
    name: 'business.admin.center.payment.add.credits',
    component: () => import('@/views/AdminCenter/Payment/AddCredit/AddCredit')
  },
  {
    path: '/c/:companyDomain/admin-center/payment/service-payment',
    name: 'business.admin.center.payment.service.payment',
    component: () => import('@/views/AdminCenter/Payment/AddCredit/ServicePayment')
  },
  {
    path: '/c/:companyDomain/workspace/:id',
    name: 'business.workspace.public',
    component: () => import('@/components/workspace/BusinessProfile'),
    redirect: { name: 'business.workspace.public.contents' },
    children: [
      {
        path: 'contents',
        name: 'business.workspace.public.contents',
        component: () => import('@/components/workspace/WorkspaceContentsTab'),
        children: [
          {
            path: 'manage/:contentId?',
            name: 'business.workspace.public.contents.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'business.workspace.public.contents'
            }
          },
          {
            path: 'content/details/:contentId',
            name: 'business.workspace.public.contents.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'business.workspace.public.contents'
            },
            children: [
              {
                path: 'list-association',
                name: 'business.workspace.public.contents.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'business.workspace.public.contents.content.details'
                }
              }
            ]
          },
          {
            path: ':contentId/list-association',
            name: 'business.workspace.public.contents.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'business.workspace.public.contents'
            }
          }
        ]
      },
      {
        path: 'lists',
        name: 'business.workspace.public.lists',
        component: () => import('@/components/workspace/WorkspaceListsTab'),
        children: [
          {
            path: 'manage/:listId?',
            name: 'business.workspace.public.lists.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'business.workspace.public.lists'
            }
          },
          {
            path: 'details/:listId',
            name: 'business.workspace.public.lists.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'business.workspace.public.lists'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'business.workspace.public.lists.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'business.workspace.public.lists.lists.details'
                },
                children: [
                  {
                    path: 'list-association',
                    name: 'business.workspace.public.lists.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'business.workspace.public.lists.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage',
                name: 'business.workspace.public.lists.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'home.user.lists'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'skills',
        name: 'business.workspace.public.skills',
        component: () => import('@/components/workspace/WorkspaceSkillsTab')
      }
    ]
  },
  {
    path: '/c/:companyDomain/tos',
    name: 'business.tos',
    component: () => import('@/views/tos')
  }
]
