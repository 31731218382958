import { hireHttp } from '@/support/axios'
import { parse } from '@/support/payloadParser'

export default {
  state: {
    positionSuggestions: []
  },
  getters: {
    getPositionSuggestions: (state) => state.positionSuggestions
  },
  mutations: {
    updatePositionSuggestions (state, positionsuggestions) {
      state.positionSuggestions = positionsuggestions
    }
  },
  actions: {
    attemptGetPositionSuggestions ({ commit }) {
      const payload = {
        salary: {
          minimum: 0,
          allowUnspecified: false
        },
        locale: 'pt-BR',
        lang: 'pt',
        length: 15,
        aggregator: this.getCompanyDomainAndWorkspace
      }
      return hireHttp.get(`v1/suggestions?${parse(payload)}`).then(({ data }) => {
        commit('updatePositionSuggestions', data)
        return data
      })
    }
  }
}
