<script>
export default {
  name: 'ModalChangeWorkspace',
  computed: {
    backgroundColor () {
      return this.wantedWorkspace.type === 'aggregator' ? this.wantedWorkspace.customStyle.primaryColor : this.wantedWorkspace.type === 'business' ? '#fff' : '#1200D3'
    },
    textColor () {
      return this.wantedWorkspace.type === 'business' ? '#1200D3' : '#fff'
    },
    getImageName () {
      if (this.wantedWorkspace.subdomain === 'empregosagro') return 'empregosagro'

      return this.wantedWorkspace.type
    },
    wantedWorkspace () {
      return this.$store.getters.getWantToChangeWorkspace
    },
    showModal () {
      return this.wantedWorkspace && !this.$store.getters.getShownModalChangeWorkspace[this.wantedWorkspace.type]
    }
  },
  watch: {
    wantedWorkspace () {
      if (this.wantedWorkspace.type === this.selectedWorkspace.type) {
        this.setShown(true)
      }
      if (this.wantedWorkspace && !this.showModal) {
        this.setShown(false)
      }
    }
  },
  methods: {
    setShown (skipSetShownCommit = false) {
      !skipSetShownCommit && this.$store.commit('setShownWorkscapeWelcome', this.wantedWorkspace.type)
      this.$router.push({ name: this.wantedWorkspace.type + '.home.user', params: { companyDomain: this.wantedWorkspace.subdomain } })
      this.$store.commit('setSelectedWorkspace', this.wantedWorkspace)
      this.$store.commit('updateWantedWorkspaceChange', null)
    },
    close () {
      this.$store.commit('updateWantedWorkspaceChange', null)
    }
  }
}
</script>
<template>
  <v-dialog v-if="wantedWorkspace" :value="showModal" width="600" persistent>
    <v-card class="modal-change-workspace--container" :color="backgroundColor">
      <div class="modal-change-workspace--header">
        <h4 :style="{color: textColor}">{{ $t(`modal.change.workspace:type.${wantedWorkspace.type}`, { name: wantedWorkspace.name }) }}</h4>
      </div>
      <div class="modal-change-workspace--body">
        <div class="image-wrapper">
          <v-img :src="`/assets/images/change-workspace/${getImageName}.png`" contain height="187"></v-img>
          <div class="business-center--logo-container" v-if="wantedWorkspace.type !== 'individual' && wantedWorkspace.subdomain !== 'empregosagro'">
            <v-avatar size="38"><v-img :src="!wantedWorkspace.avatar.includes('/empty') ? changeThumbToSize(wantedWorkspace.avatar, 'avatar') : defaultAvatarUrl('business')"></v-img></v-avatar>
          </div>
        </div>
        <p :style="{color: textColor}" v-html="$t(`modal.change.workspace:description.${getImageName}`, { name: wantedWorkspace.name })"></p>
      </div>
      <div class="modal-change-workspace--actions text-center">
        <v-btn class="btn transform-unset bold" :style="{color: wantedWorkspace.type !== 'business' ? backgroundColor : '#fff'}" :color="textColor" :dark="wantedWorkspace.type === 'business'" @click="setShown(false)">{{ $t('workspace.change.tip:confirm.btn') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-change-workspace--container {
  padding: 20px;
  z-index: 99999;
  .modal-change-workspace--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .modal-change-workspace--body {
    margin-bottom: 48px;
    .image-wrapper {
      display: inline-block;
      position: relative;
      .business-center--logo-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: calc(50% - 6px);
        left: calc(50% + 0.5px);
        transform: translateY(-50%) translateX(-50%);
        background: #fff;
        height: 65px;
        width: 65px;
        border-radius: 100px;
      }
    }
    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #141414;
      margin-top: 30px;
    }
  }
  .modal-change-workspace--actions {
    .v-btn.blue-btn {
      color: #1200D3;
    }
  }
}
</style>
