export default [
  {
    path: '/newaccount',
    name: 'newaccount.home.firstAccess',
    component: () => import('@/views/FirstAccess/FirstAccess.vue'),
    children: [
      {
        path: '/newaccount/welcome',
        name: 'newaccount.home.welcome',
        component: () => import('@/views/FirstAccess/Welcome'),
        meta: {
          hideMenu: true
        }
      },
      {
        path: 'profiles',
        name: 'newaccount.home.firstAccess.profile',
        component: () => import('@/views/FirstAccess/Profile'),
        meta: {
          hideMenu: true
        }
      },
      {
        path: 'skills',
        name: 'newaccount.home.firstAccess.skills',
        component: () => import('@/views/FirstAccess/Skills'),
        meta: {
          hideMenu: true
        }
      },
      {
        path: 'categories',
        name: 'newaccount.home.firstAccess.categories',
        component: () => import('@/views/FirstAccess/Categories'),
        meta: {
          hideMenu: true
        }
      }
    ]
  }
]
