import { hireHttp } from '@/support/axios'

export default {

  actions: {
    attemptGetPositionsByCompany (_, companyId) {
      return hireHttp.get('v1/positions/company/' + companyId).then(({ data }) => {
        return data
      })
    },
    attemptGetAllApplications () {
      return hireHttp.get('v1/matches?messagesFrom=' + (new Date()).toJSON()).then(({ data }) => {
        return data
      })
    },
    attemptGetOldApplications (_, companyName) {
      return hireHttp.get('v1/oldmatches?messagesFrom=' + (new Date()).toJSON()).then(({ data }) => {
        return data
      })
    }
  }
}
