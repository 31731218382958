import qs from 'qs'
import { diff } from 'deep-object-diff'
import { sortBy, uniq, uniqBy, cloneDeep } from 'lodash'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import { countryFromCode } from '@/support/countryinfo/country-codes.js'

export default {
  data () {
    return {
      enumInitialUpdateSettings: [
        'UPDATE_PREFERENCE_LANGUAGES'
      ],
      enumInitialSetSettings: [
        'SET_PREFERENCE_LANGUAGES'
      ],
      enumWorkspaceLinkType: [
        'INSTAGRAM',
        'LINKEDIN',
        'TWITTER',
        'FACEBOOK',
        'URL'
      ],
      enumActivitySector: [
        'technology',
        'retail',
        'health',
        'industry',
        'financial-market'
      ],
      enumInteractionsType: [
        'conclusion',
        'in_progress',
        'access',
        'drop_out'
      ],
      iconsMap: {
        course: 'mdi-laptop',
        video: 'mdi-play-circle-outline',
        article: 'mdi-text-box',
        podcast: 'mdi-microphone',
        slides: 'mdi-collage',
        book: 'mdi-book',
        event: 'mdi-calendar',
        questionnaire: 'mdi-list-status',
        list: 'mdi-format-list-bulleted'
      },
      contentLevels: ['interest', 'basic', 'intermediate', 'advanced'],
      shouldComponentUpdate: [],
      modalityLabel: [
        { text: 'discovery.modal.search:modality.bimodal', key: 'bimodal' },
        { text: 'discovery.modal.search:modality.hybrid', key: 'hybrid' },
        { text: 'discovery.modal.search:modality.online', key: 'online' },
        { text: 'discovery.modal.search:modality.realtime', key: 'online_synchronous' },
        { text: 'discovery.modal.search:modality.presential', key: 'oncampus' }
      ],
      workloadLabel: [
        { text: 'discovery.modal.search:workload.indifferent', key: 'indifferent' },
        { text: 'discovery.modal.search:workload.shortterm', key: 'shortterm' },
        { text: 'discovery.modal.search:workload.longterm', key: 'longterm' }
      ],
      pricingLabel: [
        { text: 'discovery.modal.search:workload.indifferent', key: 'indifferent' },
        { text: 'discovery.modal.search:pricing.free', key: 'free' },
        { text: 'discovery.modal.search:pricing.paid', key: 'paid' }
      ],
      telecommuteLabel: [
        { text: 'discovery.details.label:details.remote', key: true },
        { text: 'discovery.modal.search:modality.presential', key: false }
      ],
      salaryLabel: [
        { text: 'discovery.details.label:details.competitive', key: 'competitive' }
      ]
    }
  },
  computed: {
    getPrimaryColor () {
      return this.isAggregator ? this.selectedWorkspace.customStyle.primaryColor : '#1200D3'
    },
    getAccentColor () {
      return this.isAggregator ? this.selectedWorkspace.customStyle.secondaryColor : '#1200D3'
    },
    isDarkTextMenu () {
      return this.selectedWorkspace.customStyle && !!this.selectedWorkspace.customStyle.darkMenu
    },
    isAggregator () {
      return this.selectedWorkspace?.type === 'aggregator'
    },
    isBusiness () {
      return this.selectedWorkspace.type === 'business'
    },
    useTermsUrl () {
      return this.$i18n.locale === 'pt-BR' ? `https://assets.plooral.${process.env.VUE_APP_DEV_MODE ? 'dev' : 'io'}/assets/plooral-terms-and-conditions_pt-BR.pdf` : `https://assets.plooral.${process.env.VUE_APP_DEV_MODE ? 'dev' : 'io'}/assets/plooral-terms-and-conditions_en-US.pdf`
    },
    isMobile () {
      return this.windowWidth <= 800
    },
    getUserInfo () {
      return this.$store.getters.getUser
    },
    workspace () {
      return this.$store.getters.getIndividualWorkspace
    },
    isUserDeleted () {
      return this.workspace.deletionRequest && this.workspace.deletionRequest.status !== 'CANCELED'
    },
    availableLanguages () {
      return [
        { text: this.$t('global.language:pt-BR'), value: 'pt-BR' },
        { text: this.$t('global.language:en'), value: 'en' }
      ]
    },
    hireUrl () {
      return process.env.VUE_APP_HIRE_URL
    },
    baseUrl () {
      return window.location.origin
    },
    profileBelongsLoggedUser () {
      const workspaceId = this.$route.params.id ? parseInt(this.$route.params.id) : this.selectedWorkspace.id
      const userWorkspaces = this.$store.getters.getUserWorkspaces
      return userWorkspaces.find(workspace => workspace.id === workspaceId && (workspace.role === 'administrator' || workspace.role === 'owner' || workspace.type === 'individual'))
    },
    userCanCreateContent () {
      const workspaceId = this.$route.params.id ? parseInt(this.$route.params.id) : this.selectedWorkspace.id
      const userWorkspaces = this.$store.getters.getUserWorkspaces
      return userWorkspaces.find(workspace => workspace.id === workspaceId && (workspace.role === 'administrator' || workspace.role === 'owner' || workspace.role === 'content_creator' || workspace.type === 'individual'))
    },
    // selectedWorkspace () {
    //   return this.$store.getters.getSelectedWorkspace
    // },
    selectedWorkspace () {
      const availableWorkspaces = this.$store.getters.getUserWorkspaces
      const actualRoute = this.$route.name
      const companyDomain = this.$route.params.companyDomain
      if (companyDomain) {
        const current = availableWorkspaces.filter(workspace => {
          return companyDomain === workspace.subdomain
        })
        if (current.length) return current[0]
        else {
          this.$router.push({ path: '/' })
          return {}
        }
      } else if (actualRoute && !actualRoute.includes('workspace.selection.index')) {
        return availableWorkspaces.filter(workspace => {
          return workspace.type === 'individual'
        })[0]
      }
    },
    hasContentTypes () {
      return this.$store.getters.getContentTypes && this.$store.getters.getContentTypes.length
    },
    positionsCategories () {
      return this.$store.getters.getPositionsCategory
    },
    getThemeWorkspace () {
      return this.$store.getters.getThemeWorkspace
    },
    getCoursesSegments () {
      return this.$store.getters.getCoursesSegments
    },
    getCoursesProviders () {
      return this.$store.getters.getCoursesProviders
    }
  },
  methods: {
    isNumber (evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getCompanyDomainAndWorkspace () {
      return {
        workSpaceType: this.$route.name.split('.')[0],
        CompanyDomain: this.$route.params.companyDomain
      }
    },
    getBaseUrl () {
      return window.location.origin
    },
    getBackRoute () {
      return { name: this.$route.meta.backRouteName }
    },
    formatUTCFullDate (timestamp) {
      const date = new Date(timestamp)
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2)
      const day = ('0' + date.getUTCDate()).slice(-2)
      return [day, month, date.getUTCFullYear()].join('/')
    },
    brazilianToAmericanTime (brazilianTime) {
      const [hours, minutes] = brazilianTime.split(':').map(Number)
      const date = new Date()
      date.setHours(hours)
      date.setMinutes(minutes)
      const americanTime = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
      return americanTime
    },
    convertFormDataProspect (profileInfo, companyInfo = {}) {
      const dataImported = {
        avatar: profileInfo.avatar || profileInfo.images,
        name: profileInfo.firstName,
        lastName: profileInfo.lastName,
        email: profileInfo.email,
        region: profileInfo.region || companyInfo.region,
        birthdate: profileInfo.dateOfBirth ? this.formatUTCFullDate(profileInfo.personalDetails?.dateOfBirth || profileInfo.dateOfBirth) : '',
        city: profileInfo.location || '',
        cityId: profileInfo.locationId || '',
        countryAbbr: profileInfo.countryAbbr || (companyInfo.region === 'BRA' ? 'BRA' : 'USA'),
        isPCD: profileInfo.isDisabledPerson || false,
        pcdType: profileInfo.disability || '',
        pcdCID: profileInfo.medicalReportICD || '',
        needAdaptation: !!profileInfo.specialAdaptationNeeded || false,
        pcdAdaptation: profileInfo.specialAdaptationNeeded || '',
        professionalTitle: profileInfo.title || '',
        professionalDescription: profileInfo.description || '',
        preferredAreas: profileInfo.categories ? Object.values(profileInfo?.categories) : [],
        professionalExperiences: profileInfo.workExperience ? profileInfo.workExperience.map((workexp) => ({ title: workexp.title, company: workexp.companyName, dateInit: this.formatUTCMonthYear(workexp.periodIn), dateEnd: this.formatUTCMonthYear(workexp.periodOut), actualWork: workexp.isCurrentWork || !workexp.periodOut, id: workexp.id, description: workexp.description })) : [],
        hasProfessionalExp: true,
        academicExperiences: profileInfo.education?.map((education) => ({ title: education.courseName, id: education.id, isStudying: education.isStudying || !education.periodOut, institution: education.schoolName, dateInit: this.formatUTCYear(education.periodIn), dateEnd: this.formatUTCYear(education.periodOut) })) || [],
        hasAcademicExp: true,
        languages: profileInfo.languages?.map((language) => ({ name: language.id, proficiency: language.proficiency })) || [],
        socialMedia: profileInfo.socialLinks?.map(links => ({ name: this.capitalizeFirstLetter(links.name), value: links.value })) || [],
        skills: profileInfo.skills?.map(skills => skills.name) || []
      }
      dataImported.avatar = dataImported.avatar !== 'https://s3.amazonaws.com/enlizt-resources-dev/empty' ? dataImported.avatar : ''
      const phoneNumber = profileInfo.phone?.trim().replace('-', '').split(' ') || []
      if (phoneNumber.length > 0) {
        if (phoneNumber[0].includes('+')) {
          dataImported.phone = phoneNumber.length === 3 ? `${phoneNumber[1]}${phoneNumber[2]}` : phoneNumber[1]
        } else {
          if (phoneNumber[0].includes(')')) dataImported.phone = phoneNumber.length === 3 ? `${phoneNumber[0]}${phoneNumber[1]}${phoneNumber[2]}` : phoneNumber[1]
        }
        dataImported.countryCode = phoneNumber[0].includes('+') ? countryFromCode(phoneNumber[0].replace('+', '')) : this.getCountryDialCode(dataImported.countryAbbr)
      } else {
        dataImported.phone = ''
        // dataImported.countryCode = this.getDefaultCountryDialCode()
      }
      return dataImported
    },
    formatUTCYear (timestamp) {
      if (!timestamp) return timestamp
      const date = new Date(timestamp)
      return date.getUTCFullYear().toString()
    },
    formatUTCMonthYear (timestamp) {
      if (!timestamp) return timestamp
      const date = new Date(timestamp)
      const month = ('0' + (date.getUTCMonth() + 1)).slice(-2)
      return [month, date.getUTCFullYear()].join('/')
    },
    updateComponent (componentName) {
      this.shouldComponentUpdate[componentName] = new Date().getTime()
    },
    userCanManage (workspaceId) {
      return this.$store.getters.getUserWorkspaces.some(workspace => {
        return (workspace.lxID === workspaceId || workspace.id === workspaceId) && (workspace.role === 'administrator' || workspace.role === 'owner' || workspace.type === 'individual' || workspace.userRole === 'admin')
      })
    },
    userManagesContent (workspaceId) {
      return this.$store.getters.getUserWorkspaces.some(workspace => {
        return (workspace.lxID === workspaceId || workspace.id === workspaceId) && (workspace.role === 'administrator' || workspace.role === 'owner' || workspace.role === 'content_creator' || workspace.type === 'individual' || workspace.userRole === 'admin')
      })
    },
    setSkillColor (skillLevel) {
      switch (skillLevel) {
        case 'interest':
          return 'neutral-light'
        case 'basic':
          return 'secondary-lightest'
        case 'intermediate':
          return 'secondary-light'
        case 'advanced':
          return 'secondary-medium'
      }
    },
    formatDate (date, format = 'short', lang = 'pt-BR') {
      if (format === 'short') {
        return date ? this.$d(new Date(date), format, lang) : ''
      } else if (format === 'full') {
        return `${this.getWeekDay(date)}, ${this.formatDate(date, 'short', lang)}`
      }
    },
    getTime (date) {
      const parsedDate = new Date(date).toString()
      return parsedDate.split(' ')[4].slice(0, 5)
    },
    getWeekDay (date) {
      const parsedDate = new Date(date).toString()
      return this.$t(`global:weekday.${parsedDate.split(' ')[0]}`)
    },
    longTextTreatment (text, length = 50) {
      if (text.length > length) {
        return text.slice(0, length) + '...'
      }
      return text
    },
    orderByField (arr, field, desc = false, isDate = false) {
      function setDate (item) {
        return new Date(item[field])
      }
      if (desc) {
        return sortBy(arr, isDate ? setDate : (item) => item[field]).reverse()
      }
      return sortBy(arr, isDate ? setDate : item => item[field])
    },
    isInvalid (validationItem) {
      return validationItem.$dirty && validationItem.$invalid
    },
    debounceEvent (callback, time = 300) {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.interval = null
        callback(arguments)
      }, time)
    },
    totalDurationByMs (duration) {
      if (duration > 3600000) {
        const hours = this.totalDurationByUnit(duration, 'hours')
        const minutes = duration % (1000 * 60 * 60) / (60 * 1000)

        let computedTime = `${Math.floor(hours)} ${this.$tc('global:hours', Math.floor(hours))}`

        if (minutes) {
          computedTime += ` ${this.$t('global:and').toLocaleLowerCase()} ${minutes} ${this.$tc('global:minutes', minutes)}`
        }

        return computedTime
      }
      return `${duration / (1000 * 60)} ${this.$tc('global:minutes', duration / (1000 * 60))}`
    },
    totalDurationByUnit (duration, unit = 'minutes') {
      switch (unit) {
        case 'minutes':
          return duration / (1000 * 60)

        case 'hours':
          return duration / (1000 * 60 * 60)

        case 'days':
          return duration / (1000 * 60 * 60 * 24)
      }
    },
    removeDuplicatesFromArray (arr, byField = null) {
      if (!byField) return uniq(arr)
      return uniqBy(arr, (e) => e[byField])
    },
    getGreetingSaudation () {
      const today = new Date()
      const curHr = today.getHours()

      if (curHr < 12) {
        return this.$t('global:good.morning')
      } else if (curHr < 18) {
        return this.$t('global:good.afternoon')
      } else {
        return this.$t('global:good.evening')
      }
    },
    smoothScrollTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    getDefaultRegion () {
      const userInfo = this.$store.getters.getUser
      switch (userInfo.language) {
        case 'pt':
          return 'BRA'
        case 'en':
          return 'USA'
        default:
          return 'USA'
      }
    },
    fixedCourseUrl (url) {
      const { workSpaceType, CompanyDomain } = this.getCompanyDomainAndWorkspace()
      if (workSpaceType === 'aggregator') {
        const fixedUrl = new URL(url)
        fixedUrl.searchParams.set('utm_source', `portal-${CompanyDomain}`)
        return fixedUrl.toString()
      } else return url
    },
    getDefaultCountryDialCode () {
      const userInfo = this.$store.getters.getUser
      switch (userInfo.language) {
        case 'pt':
          return 'br'
        case 'en':
          return 'us'
        default:
          return 'us'
      }
    },
    getCountryDialCode (value) {
      switch (value) {
        case 'BRA':
          return 'br'
        case 'USA':
          return 'us'
        case 'ITA':
          return 'it'
        default:
          return 'us'
      }
    },
    capitalizeFirstLetter (string) {
      if (string.toLowerCase() === 'github') return 'GitHub'
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    convertFormData (profileInfo) {
      const dataImported = {
        name: profileInfo.personalDetails?.firstName,
        lastName: profileInfo.personalDetails?.lastName,
        email: profileInfo.personalDetails?.email,
        skills: profileInfo?.skills || [],
        region: profileInfo.personalDetails?.region || this.getDefaultRegion(),
        birthdate: profileInfo.personalDetails?.dateOfBirth ? this.formatUTCFullDate(profileInfo.personalDetails.dateOfBirth) : '',
        city: profileInfo.personalDetails?.location || '',
        cityId: profileInfo.personalDetails?.locationId || '',
        countryAbbr: profileInfo.personalDetails?.countryAbbr || profileInfo.personalDetails?.region || this.getDefaultRegion(),
        isPCD: profileInfo.personalDetails?.isDisabledPerson || false,
        pcdType: profileInfo.personalDetails?.disability || '',
        pcdCID: profileInfo.personalDetails?.medicalReportICD || '',
        needAdaptation: !!profileInfo.personalDetails?.specialAdaptationNeeded || false,
        pcdAdaptation: profileInfo.personalDetails?.specialAdaptationNeeded || '',
        professionalTitle: profileInfo.personalDetails?.title || '',
        professionalDescription: profileInfo.personalDetails?.description || '',
        preferredAreas: profileInfo.categories ? Object.values(profileInfo?.categories) : [],
        professionalExperiences: profileInfo.workExperience ? profileInfo.workExperience.map((workexp) => ({ title: workexp.title, company: workexp.companyName, dateInit: this.formatUTCMonthYear(workexp.periodFrom), dateEnd: this.formatUTCMonthYear(workexp.periodTo), actualWork: workexp.isCurrentWork, id: workexp.id, description: workexp.description })) : [],
        hasProfessionalExp: true,
        academicExperiences: profileInfo.education.map((education) => ({ title: education.course, id: education.id, isStudying: education.isStudying, institution: education.school, dateInit: this.formatUTCYear(education.periodFrom), dateEnd: this.formatUTCYear(education.periodTo) })),
        hasAcademicExp: true,
        languages: profileInfo.languages ? Object.keys(profileInfo.languages)?.map((language) => ({ name: language, proficiency: profileInfo.languages[language] })) : [],
        socialMedia: profileInfo.socialLinks ? Object.keys(profileInfo.socialLinks)?.map((link) => {
          if (profileInfo.socialLinks[link] === '') return
          return { type: this.capitalizeFirstLetter(link), value: profileInfo.socialLinks[link] }
        }).filter(link => link !== undefined) : []
      }
      const phoneNumber = profileInfo.personalDetails.phone?.trim().replace('-', '').split(' ') || []
      if (phoneNumber.length > 0) {
        if (phoneNumber[0].includes('+')) {
          dataImported.phone = phoneNumber.length === 3 ? `${phoneNumber[1]}${phoneNumber[2]}` : phoneNumber[1]
        } else {
          if (phoneNumber[0].includes(')')) dataImported.phone = phoneNumber.length === 3 ? `${phoneNumber[0]}${phoneNumber[1]}${phoneNumber[2]}` : phoneNumber[1]
        }
        dataImported.countryCode = phoneNumber[0].includes('+') ? countryFromCode(phoneNumber[0].replace('+', '')) : this.getCountryDialCode(dataImported.countryAbbr)
      } else {
        dataImported.phone = ''
        dataImported.countryCode = this.getDefaultCountryDialCode()
      }
      return dataImported
    },
    arrayEquals (a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    },
    clearStorageAndLogout (redirectUri = null) {
      this.$store.commit('setShowAppBanner', true)
      if (this.$store.getters.getThemeWorkspace) location.href = '/login?theme=' + this.$store.getters.getThemeWorkspace
      else location.href = location.origin
      localStorage.clear()
    },
    passwordRulesValidation (password, rule) {
      const validRgx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*@!#%&()^~{}$])[A-Za-z\d*@!#%&()^~{}$]{8,}$/
      const specialRgx = /[*@!#%&()^~{}$]+/
      const upperRgx = /[A-Z]+/
      const lowerRgx = /[a-z]+/
      const numberRgx = /[0-9]+/

      switch (rule) {
        case 'valid': return validRgx.test(password)
        case 'special': return specialRgx.test(password)
        case 'upper': return upperRgx.test(password)
        case 'lower': return lowerRgx.test(password)
        case 'number': return numberRgx.test(password)
        default: return false
      }
    },
    pushToRouteName (routeOptions) {
      let defaultRoute
      if (!Array.isArray(routeOptions) && this.$route.name.includes(routeOptions.dir)) {
        this.$router.push({ name: routeOptions.path, params: routeOptions.params, query: routeOptions.query })
        return
      } else if (Array.isArray(routeOptions)) {
        for (const { dir, path, params, changeState, query } of routeOptions) {
          if (changeState) {
            this.$store.dispatch(changeState.actionName, changeState.value)
          }
          if (this.$route.name.includes(dir)) {
            this.$router.push({ name: path, params, query })
            return
          }
          if (dir === 'default') {
            defaultRoute = { name: path, params, query }
          }
        }
      }
      if (!defaultRoute) defaultRoute = { name: this.selectedWorkspace.type + '.home.user', params: routeOptions.params, query: routeOptions.query }
      this.$router.push(defaultRoute)
    },
    objIsEmpty (obj) {
      if (!obj) return false
      for (const prop in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(prop)) {
          return false
        }
      }
      return JSON.stringify(obj) === JSON.stringify({})
    },
    defaultAvatarUrl (source = 'card') {
      return `/assets/images/${source}-avatar.jpg`
    },
    checkIfObjectsHasDifferences (originObj, updatedObj) {
      const result = diff(originObj, updatedObj)
      return result && !this.objIsEmpty(result)
    },
    cloneObj (obj) {
      return cloneDeep(obj)
    },
    isWorkspaceDeleted (workspaceId) {
      const workspace = this.$store.getters.getWorkspaceInfos[workspaceId] || null
      return workspace && workspace.deletionRequest && workspace.deletionRequest.status !== 'CANCELED'
    },
    isUserWorkspaceDeleted (workspaceId) {
      const workspace = this.$store.getters.getUserWorkspaces.find(workspace => workspace.id === workspaceId) || null
      return workspace && workspace.deletionRequest && workspace.deletionRequest.status !== 'CANCELED'
    },
    changeThumbToSize (thumbUrl, size) {
      const availableSizes = ['avatar', 'small', 'medium', 'large']
      const actualSize = availableSizes.find(availableSize => new RegExp(`thumbnails/${availableSize}`).test(thumbUrl))
      if (actualSize) {
        return thumbUrl.replace(actualSize, size)
      }
      return thumbUrl
    },
    addHeadersToList (lists) {
      function compareWorkspaceName (list1, list2) {
        return list1 && list1.workspaceName && list1.workspaceName.localeCompare(list2.workspaceName)
      }
      const headers = new Set()
      const sortedLists = [...lists].sort(compareWorkspaceName)
      for (const list of sortedLists) {
        if (!headers.has(list.workspaceName)) {
          sortedLists.splice(sortedLists.indexOf(list), 0, { header: list.workspaceType === 'business' ? list.workspaceName : this.$t('global:personal.workspace') })
          headers.add(list.workspaceName)
        }
      }
      return sortedLists
    },
    isValidBlobSize (blob, maxSize = 1) {
      return blob && (blob.size / 1024) / 1024 < maxSize
    },
    parseEnliztLanguage (lng) {
      switch (lng) {
        case 'en':
          return 'en-us'
        case 'pt-BR':
          return 'pt-br'
        default:
          return lng
      }
    },
    SSORedirect () {
      if (this.$route.query && this.$route.query.redirect) {
        const redirectInfo = qs.parse(this.$route.query.redirect)
        this.$router.push({ name: redirectInfo.name, params: redirectInfo.params })
      }
    },
    isEmail (value) {
      // eslint-disable-next-line no-control-regex
      return /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(value)
    },
    setupZendeskCustomFields () {
      if (!process.env.VUE_APP_ZE_CUSTOM_FIELDS) return

      const customFields = JSON.parse(process.env.VUE_APP_ZE_CUSTOM_FIELDS)
      const enterpriseWorkspaces = this.$store.getters.getUserWorkspaces.filter(workspace => workspace.type === 'business')
      const filledFields = customFields.reduce((acc, curr) => {
        if (curr.name === 'userType') {
          return acc.concat({
            id: curr.id,
            prefill: {
              '*': enterpriseWorkspaces && enterpriseWorkspaces.some(workspace => workspace.idpAccessHire) ? 'Company' : 'Candidate'
            }
          })
        }
        if (curr.name === 'businessWorkspaces') {
          return acc.concat({
            id: curr.id,
            prefill: {
              '*': enterpriseWorkspaces && JSON.stringify(enterpriseWorkspaces.map(workspace => ({ id: workspace.id, name: workspace.name, role: workspace.role })))
            }
          })
        }
        if (curr.name === 'language') {
          return acc.concat({
            id: curr.id,
            prefill: {
              '*': this.$store.getters.getLanguage
            }
          })
        }
        if (curr.name === 'device') {
          return acc.concat({
            id: curr.id,
            prefill: {
              '*': navigator.userAgent
            }
          })
        }
      }, [])

      // eslint-disable-next-line no-undef
      zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            fields: filledFields
          }
        }
      })
    },
    markdownToHtml (markdown, options) {
      // return marked(text)

      const removeBackslashEscapes = (str) => {
        const backslashEscapes = ['\\', ',', '`', '*', '_', '{', '}', '[', ']', '(', ')', '#', '+', '-', '.', '!']
        let result = str
        let re
        for (let i = 0, l = backslashEscapes.length; i < l; i++) {
          re = new RegExp('\\\\\\' + backslashEscapes[i], 'g')
          result = result.replace(re, backslashEscapes[i])
        }
        return result
      }

      const removeTags = (html) => {
        return html.replace(/</gm, '&lt;').replace(/>/gm, '&gt;')
      }

      const renderer = new marked.Renderer()
      renderer.heading = (c, l) => {
        if (options && options.h1) l += options.h1
        return `<h${l}>${c}</h${l}>`
      }
      renderer.link = (h, t, c) => {
        if (options && options.link) return `<a href="${removeBackslashEscapes(h)}">${c}</a>`
        return c
      }
      renderer.image = (h, t, c) => {
        if (c === 'youtube_video' && h.indexOf('https://img.youtube.com') === 0) {
          const urlParts = h.split('/')
          return '<iframe id="player" frameborder="0" width="560" height="315" src="https://www.youtube.com/embed/' + urlParts[4] + '?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;controls=0" allowfullscreen allowtransparency allow="autoplay"></iframe>'
        } else {
          if (t) return `<img src="${h}" alt="${c}" title="${t}">`
          return `<img src="${h}" alt="${c}" class="full-banner">`
        }
      }
      renderer.html = (c) => {
        return removeTags(c)
      }
      const markedOptions = {
        renderer
      }
      const purifyOptions = {
        SAFE_FOR_JQUERY: true,
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        KEEP_CONTENT: false
      }
      return DOMPurify.sanitize(marked(markdown, markedOptions), purifyOptions)
    },
    formatSalary (position) {
      let salary
      if (['text', 'range'].includes(position.salaryType)) {
        const values = position.salary.split(' - ')
        if (values[0] === values[1]) salary = new Intl.NumberFormat().format(values[0])
        else salary = `${new Intl.NumberFormat().format(values[0])} - ${new Intl.NumberFormat().format(values[1])}`
        if (position.salaryPeriod) salary += ` (${this.$t('discovery.details.label:details.' + position.salaryPeriod)})`
      } else salary = this.$t('discovery.details.label:details.' + position.salary)
      return salary
    },
    parseTextFormat (filter) {
      if (filter.key === 'duration') {
        if (filter.text.to && filter.text.from) return this.$t(`advanced.filter:active.filter.duration.full.${filter.text.type}`, { ...filter.text })
        if (filter.text.to && !filter.text.from) return this.$tc(`advanced.filter:active.filter.duration.to.${filter.text.type}`, filter.text.to, { ...filter.text })
        if (!filter.text.to && filter.text.from) return this.$tc(`advanced.filter:active.filter.duration.from.${filter.text.type}`, filter.text.from, { ...filter.text })
      }

      // const getSkillTitle = () => {
      //   const skill = this.availableSkills.find(skill => skill.id === filter.text || (filter.text && skill.id === filter.text.id))
      //   return skill && skill.title
      // }

      switch (filter.key) {
        case 'category':
          return this.positionsCategories.filter(id => id.id === filter.text)[0].title
        case 'undefinedSalary':
          return this.$t('discovery.modal.search:undefined.salary')
        case 'exclusivePCD':
          return this.$t('discovery.list.search:accessibility')
        case 'remoteWorkOnly':
          return this.$t('discovery.modal.search:workplace.remote.only')
        case 'modality':
          if (!filter.text) return ''
          return this.$t(this.modalityLabel.filter(modality => modality.key === filter.text)[0].text)
        case 'workload':
          if (!filter.text || Number.isInteger(filter.text)) {
            return filter.text > 1 ? `${filter.text} ${this.$t('global:hours.plural')}` : `${filter.text} ${this.$t('global:hour.singular')}`
          } else {
            const result = this.workloadLabel.filter(modality => modality.key === filter.text)
            if (result.length > 0) {
              return this.$t(this.workloadLabel.filter(modality => modality.key === filter.text)[0].text)
            } else {
              return ''
            }
          }
        case 'pricing':
          if (!filter.text) return this.$t('discovery.modal.search:pricing.free')
          if (Number.isInteger(filter.text)) {
            const value = Number(filter.text).toLocaleString(`${filter.currency === 'USD' ? 'en-US' : 'pt-BR'}`, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            return value
          }
          if (!isNaN(filter.text)) return Number(filter.text).toLocaleString()
          return this.$t(this.pricingLabel.filter(modality => modality.key === filter.text)[0].text)
        case 'institute':
          return this.$t(this.getCoursesProviders.filter(modality => modality._id === filter.text)[0].name)
        case 'segment':
          return this.$t(this.getCoursesSegments.filter(modality => modality._id === filter.text)[0].name)
        case 'skills':
          return filter.text
        case 'categories':
          if (!filter.text) return ''
          return this.$t(`advanced.filter:active.filter.categories.${filter.text}`)
        case 'languages':
          if (!filter.text) return ''
          return this.$t(`advanced.filter:active.filter.languages.${filter.text}`)
        case 'levels':
          if (!filter.text) return ''
          return this.$t(`advanced.filter:active.filter.levels.${filter.text}`)
        case 'activitiesSectors':
          return this.$t(`activity.sector.default:${filter.text.toLowerCase()}`)
        case 'location':
          if (!filter.text) return ''
          return filter.text
        case 'remuneration':
          if (!filter.text) return ''
          return filter.text
        case 'telecommute':
          if (filter.text === null) return ''
          return this.$t(this.telecommuteLabel.filter(telecommute => telecommute.key === filter.text)[0].text)
        case 'salary':
          if (!filter.text) return ''
          if (!!filter.text && filter.text !== 'competitive') return filter.text
          return this.$t(this.salaryLabel.filter(salary => salary.key === filter.text)[0].text)
        default:
          return filter.text
      }
    },
    getWorkspaceRoot () {
      switch (this.selectedWorkspace.type) {
        case 'aggregator':
          return `/p/${this.selectedWorkspace.subdomain}`
        case 'individual':
          return '/my'
        case 'business':
          return `/c/${this.selectedWorkspace.subdomain}`
      }
    },
    getTheme (route) {
      let setThemeBy = null
      let workspaces = JSON.parse(localStorage.getItem('vuex')).Workspace.userWorkspaces
      if (Object.prototype.hasOwnProperty.call(route, 'aggregator')) {
        setThemeBy = route.aggregator
        workspaces = JSON.parse(localStorage.getItem('availableAggregators'))
      } else if (Object.prototype.hasOwnProperty.call(route, 'company')) setThemeBy = route.company
      const filteredTheme = workspaces.filter(theme => theme.subdomain === setThemeBy)
      if (filteredTheme.length && filteredTheme[0].type !== 'business') {
        return {
          name: filteredTheme[0].subdomain,
          primaryColor: filteredTheme[0].customStyle.primaryColor,
          secondaryColor: filteredTheme[0].customStyle.secondaryColor,
          logo: filteredTheme[0].subdomain === 'empregosagro' ? '/assets/images/logo-CNA.png' : filteredTheme[0].customStyle.logo.src
        }
      }
      return {
        name: setThemeBy,
        primaryColor: this.getPrimaryColor,
        secondaryColor: this.getAccentColor,
        logo: '/assets/images/primary-logo.svg'
      }
    },
    setPlooralGlobalToken (token) {
      const plooralGlobal = JSON.parse(localStorage.getItem('plooral-global'))
      localStorage.setItem('plooral-global', JSON.stringify({ ...plooralGlobal, token: token }))
    }
  }
}
