import { hireHttp, openHttp } from '@/support/axios'
import axios from 'axios'

export default {
  state: {
    departments: [],
    inviteDetails: {}
  },
  getters: {
    getInviteDetails: (state) => state.inviteDetails
  },
  mutations: {
    setInviteDetails (state, data) {
      state.inviteDetails = data
    },
    setIntegrations (state, data) {
      state.integrations = data
    }
  },
  actions: {
    attemptGetCompanyDetails () {
      return hireHttp.get('v1/-/company/details')
    },
    attemptUpdateCompanyDetails (_, data) {
      return hireHttp.put('v1/-/company/details', { details: data })
    },
    attemptGetStates (_, country) {
      return hireHttp.get(`v1/states?country_code=${country}`)
    },
    attemptGetCities (_, address) {
      return hireHttp.get(`v1/cities?query=${address.city}${address.state ? '&state=' + address.state : ''}&appendState=false&country_code=${address.country}`)
    },
    attemptGetCEP (_, cep) {
      return openHttp.get(`https://viacep.com.br/ws/${cep}/json/`)
    },
    // Company details US
    attemptGetCompanyDetailsUs () {
      return hireHttp.get('v1/-/company/details_us')
    },
    attemptUpdateCompanyDetailsUs (_, data) {
      return hireHttp.put('v1/-/company/details_us', { details: data })
    },
    // Company users
    attemptGetUsers () {
      return hireHttp.get('v1/-/company/users')
    },
    attemptGetUser (_, userId) {
      return hireHttp.get('v1/-/company/users/' + userId)
    },
    attemptUpdateUsers (_, user) {
      return hireHttp.put('v1/-/company/users/' + user.id, user)
    },
    attemptReactivateUser (_, user) {
      return hireHttp.post('v1/-/company/users/' + user.id, user)
    },
    attemptDeleteUser (_, user) {
      return hireHttp.delete('v1/-/company/users/' + user.id, { data: user })
    },
    attemptGetApprovalFlow () {
      return hireHttp.get('v1/-/approvalflow/')
    },
    attemptSendInvite (_, body) {
      return hireHttp.post('v1/-/company/users', body)
    },
    attemptGetInvite (_, inviteId) {
      return hireHttp.get('v1/-/company/users/invites/' + inviteId)
    },
    attemptGetInvites () {
      return hireHttp.get('v1/-/company/users/invites')
    },
    attemptAcceptInvite (_, invite) {
      return hireHttp.post('v1/-/company/users/invites/' + invite.inviteId, invite)
    },
    attemptResendInvite (_, email) {
      return hireHttp.put('v1/-/company/users/invites/', { email: email })
    },
    attemptDeleteInvite (_, email) {
      return hireHttp.delete('v1/-/company/users/invites/', { data: { email } })
    },
    // Departments
    attemptGetDepartments () {
      return hireHttp.get('v1/-/departments')
    },
    attemptGetDepartmentDetails (_, id) {
      return hireHttp.get(`v1/-/departments/${id}`)
    },
    attemptSaveDepartment (_, name) {
      return hireHttp.post('V1/-/departments/', { name: name })
    },
    attemptDeleteDepartments (_, id) {
      return hireHttp.delete(`v1/-/departments/${id}`)
    },
    attemptReactivateDepartments (_, id) {
      return hireHttp.put(`v1/-/departments/${id}`)
    },
    // Bookmarks Functions
    attemptGetDependents () {
      return hireHttp.get('v1/-/dependents')
    },
    attemptGetDependent (_, dependentId) {
      return hireHttp.get('v1/-/dependents/' + dependentId)
    },
    attemptUpdateDependent (_, data) {
      return hireHttp.put('v1/-/dependents/' + data._id, { data: data })
    },
    attemptDeleteDependent (_, dependentId) {
      return hireHttp.delete('v1/-/dependents/' + dependentId)
    },
    attemptSaveDependent (_, data) {
      return hireHttp.post('v1/-/dependents/', { data: data })
    },
    // Support Functions
    attemptGetSupport (_, data) {
      return hireHttp.get('v1/-/company/', { lang: data })
    },
    attemptPutSupport (_, data) {
      return hireHttp.put('v1/-/company/support', { mode: data })
    },
    // Advertising-Integrations Functions
    attemptGetCompany (_, data) {
      return hireHttp.get('v1/-/company/?lang=' + data)
    },
    attemptPutCompanyLinkedin (_, linkedinId) {
      return hireHttp.put('v1/-/company/', { linkedinId: linkedinId })
    },
    attemptSaveIntegrations (_, data) {
      return hireHttp.post('v1/-/automations/install/' + data.title, { token: data.token, name: data.name }).then(data => {
        return data
      })
    },
    attemptPutNotification (_, data) {
      return hireHttp.put('v1/-/integration/hiringnotification', { name: data.name, token: data.token })
    },
    attemptDeleteNotification () {
      return hireHttp.delete('v1/-/integration/hiringnotification')
    },
    attemptGetIntegrations ({ commit }) {
      return hireHttp.get('v1/-/integrations/').then(({ data }) => {
        return data
      })
    },
    attemptGetIntegrationToken (_, data) {
      return hireHttp.get('v1/-/automations/get_token/' + data.integration + '/' + data.id).then(data => {
        return data
      })
    },
    attemptDeleteIntegration (_, data) {
      const body = {
        company_automation_id: data.companyAutomationId
      }
      return hireHttp.delete('v1/-/automations/uninstall/' + data.integrationName, { data: body })
    },
    attemptGetCompanyAutomations (_, data) {
      return hireHttp.get('v1/-/companyautomations/' + data)
    },
    attemptGetWallet (_, { filters }) {
      const orderList = filters.orderList === 'all' ? '' : filters.orderList === 'orderDown' ? '&restrict=debit' : '&restrict=credit'
      return hireHttp.get(`v1/-/wallet?from=${filters.startDate}${orderList}&to=${filters.endDate}`)
    },
    attempAddVoucher (_, { voucher }) {
      return hireHttp.post('v1/-/wallet/voucher/add', { voucher })
    },
    attempGetCurrencyRate () {
      return hireHttp.get('v1/-/wallet/rates')
    },
    attempGetCard () {
      return hireHttp.get('v1/-/wallet/card')
    },
    attempDeleteCard (_, { token }) {
      return hireHttp.delete('v1/-/wallet/card', { data: { token: token } })
    },
    attempNotifyTrack (_, { track }) {
      return hireHttp.post('v1/-/notify/track', { ...track })
    },
    attempPjBanckPayWithToken (_, { paymentData }) {
      return hireHttp.post('v1/-/wallet/pjbank', { ...paymentData })
    },
    attempPjBanckPay (_, { paymentData }) {
      const domain = 'dev'
      const pjbankHttp = axios.create({ baseURL: domain === 'prod' ? 'https://api.pjbank.com.br/recebimentos' : 'https://sandbox.pjbank.com.br/recebimentos' })
      const url = domain === 'prod' ? '/6c7ce2d7a6e1989c7a277ba9b0b31b36d3c9d03a/tokens' : '/e0727263cc7a983f0aae5411ad86c5a144b8ed28/tokens'
      const data = { numero_cartao: paymentData.number }
      const options = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      return pjbankHttp.post(url, data, options)
    },
    attempPjBanckPayBankPayment (_, { paymentData }) {
      return hireHttp.post('v1/-/wallet/boleto', { ...paymentData })
    },
    attempServicePayment () {
      return hireHttp.get('v1/-/servicepayment')
    },
    attempMessages () {
      return hireHttp.get('v1/-/messages')
    },
    attempPjBanckPayBankPaymenService (_, { paymentData }) {
      return hireHttp.post('v1/-/servicepayment/boleto', { ...paymentData })
    },
    attempPjBanckPayWithTokenService (_, { paymentData }) {
      return hireHttp.post('v1/-/servicepayment/pjbank', { ...paymentData })
    },
    attempPaypalPayment (_, { paymentData }) {
      return hireHttp.post('v1/-/wallet/paypal/create', { ...paymentData })
    },
    attempPaypalPaymentAuthorize (_, { data }) {
      return hireHttp.post('v1/-/wallet/paypal/execute', { ...data })
    },
    attempStripePayment (_, { paymentData }) {
      return hireHttp.post('v1/-/wallet/stripe/charge', { ...paymentData })
    }
  }
}
