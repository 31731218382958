import { http } from '@/support/axios'
import { parseToFormDataWithArray, createQuery } from '@/support/payloadParser'

export default {
  state: {
    userLists: [],
    userListsInProgress: {
      in_progress: [],
      conclusion: []
    },
    userFavoriteLists: [],
    currentListFilters: {},
    ownedLists: []
  },
  getters: {
    getUserLists: state => state.userLists,
    getUserInProgressLists: state => state.userListsInProgress.in_progress,
    getUserConcludedLists: state => state.userListsInProgress.conclusion,
    getCurrentListFilters: state => state.currentListFilters,
    getUserFavoriteLists: state => state.userFavoriteLists,
    getOwnedLists: state => state.ownedLists
  },
  mutations: {
    updateUserLists (state, data) {
      state.userLists = data
    },
    updateUserListsInProgress (state, { status, data }) {
      state.userListsInProgress[status] = data
    },
    updateCurrentListFilters (state, value) {
      state.currentListFilters = value
    },
    updateUserFavoritesLists (state, data) {
      state.userFavoriteLists = data
    },
    toggleUserFavoriteList (state, listId) {
      const findFavorite = state.userFavoriteLists.findIndex(list => list.id === listId)
      if (findFavorite !== -1) {
        state.userFavoriteLists.splice(findFavorite, 1)
      }
      state.userFavoriteLists = [...state.userFavoriteLists]
    },
    updateOwnedLists (state, lists) {
      state.ownedLists = lists
    }
  },
  actions: {
    attemptSaveList (_, { data, id }) {
      const parsedFormData = parseToFormDataWithArray(data)
      if (data.coverImage && typeof data.coverImage === 'object') {
        parsedFormData.append('coverImage', data.coverImage, data.coverImage.name)
      }
      return http.post(`v1/lists${id ? `/${id}` : ''}`, parsedFormData)
    },
    attemptUpdateCurrentListFilters ({ commit }, data) {
      commit('updateCurrentListFilters', data)
    },
    attemptGetMyselfLists ({ commit }, filter = null) {
      const query = createQuery({ filter, order: 'DESC' })
      http.get(`v1/myself/lists?${query}`).then(({ data }) => {
        commit('updateUserLists', data)
      })
    },
    attemptGetMyselfFavoriteLists ({ commit }) {
      return http.get('v1/myself/saves').then(({ data }) => {
        commit('updateUserFavoritesLists', data.data)
        return data.data
      }).catch(() => {
        return { error: true }
      })
    },
    attemptGetListsByUser (_, { filter, userId }) {
      const query = createQuery({ filter: filter || null, order: 'DESC' })
      return http.get(`v1/lists/user/${userId}?${query}`).then(({ data }) => {
        return data
      }).catch(() => {
        return { error: true }
      })
    },
    attemptGetListById (_, listId) {
      return http.get(`v1/lists/${listId}`)
    },
    attemptGetFilteredUserLists ({ commit }, { userId, filter }) {
      const query = createQuery({ filter, order: 'DESC' })
      const url = `v1/lists/user/${userId}?${query}`
      return http.get(url).then(result => {
        commit('updateUserListsInProgress', { status: filter.status, data: result.data })
        return result
      }).catch(() => {
        return { error: true }
      })
    },
    attemptRemoveList (_, listId) {
      return http.delete(`v1/lists/${listId}`).then(({ data }) => data)
    },
    attemptToggleFavoriteList (_, listId) {
      return http.post(`v1/myself/lists/${listId}/toggle-favoritism`)
    },
    attemptGetOwnedLists ({ commit }) {
      return http.get('v1/myself/lists/owned').then(({ data }) => {
        commit('updateOwnedLists', data)
        return data
      })
    },
    attemptSetListInteraction (_, { listId, interaction }) {
      return http.post(`v1/lists/${listId}/interaction`, { interaction })
    }
  }
}
