export default [
  {
    path: '/workspace/:id',
    name: 'workspace.public',
    component: () => import('@/components/workspace/BusinessProfile'),
    redirect: { name: 'workspace.public.contents' },
    children: [
      {
        path: 'contents',
        name: 'workspace.public.contents',
        component: () => import('@/components/workspace/WorkspaceContentsTab'),
        children: [
          {
            path: 'manage/:contentId?',
            name: 'workspace.public.contents.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'workspace.public.contents'
            }
          },
          {
            path: 'content/details/:contentId',
            name: 'workspace.public.contents.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'workspace.public.contents'
            },
            children: [
              {
                path: 'list-association',
                name: 'workspace.public.contents.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'workspace.public.contents.content.details'
                }
              }
            ]
          },
          {
            path: ':contentId/list-association',
            name: 'workspace.public.contents.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'workspace.public.contents'
            }
          }
        ]
      },
      {
        path: 'lists',
        name: 'workspace.public.lists',
        component: () => import('@/components/workspace/WorkspaceListsTab'),
        children: [
          {
            path: 'manage/:listId?',
            name: 'workspace.public.lists.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'workspace.public.lists'
            }
          },
          {
            path: 'details/:listId',
            name: 'workspace.public.lists.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'workspace.public.lists'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'workspace.public.lists.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'workspace.public.lists.lists.details'
                },
                children: [
                  {
                    path: 'list-association',
                    name: 'workspace.public.lists.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'workspace.public.lists.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage',
                name: 'workspace.public.lists.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'home.user.lists'
                }
              }
            ]
          }
        ]
      },
      {
        path: 'skills',
        name: 'workspace.public.skills',
        component: () => import('@/components/workspace/WorkspaceSkillsTab')
      }
    ]
  }
]
