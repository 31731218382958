export default [
  {
    path: '/discovery/list/details/:listId',
    name: 'list.details',
    component: () => import('@/components/lists/ModalListDetails'),
    redirect: { name: 'individual.home.user.content.details' }
  },
  {
    path: '/discovery/content/details/:contentId',
    name: 'content.details',
    component: () => import('@/components/contents/ModalContentDetails'),
    redirect: { name: 'individual.home.user.content.details' }
  },
  {
    path: '/my/interview/:id',
    name: 'individual.interview',
    component: () => import('@/views/Interview')
  },
  {
    path: '/my',
    name: 'individual.home',
    component: () => import('@/views/NewHome'),
    redirect: { name: 'individual.home.user' },
    children: [
      {
        path: '/',
        name: 'individual.home.user',
        component: () => import('@/components/home/index/homepage-individual'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'individual.home.user.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'individual.home.user'
            }
          },
          {
            path: 'content/:contentId',
            name: 'individual.home.user.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'individual.home.user'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'individual.home.user.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.home.user.content.details'
                }
              }
            ]
          },
          {
            path: 'contents/pathways/:listId?/manage',
            name: 'individual.home.user.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'individual.home.user'
            }
          },
          {
            path: 'contents/pathways/:listId',
            name: 'individual.home.user.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'individual.home.user'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'individual.home.user.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'individual.home.user.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.home.user'
                }
              }
            ]
          },
          {
            path: ':contentId/pathway-association',
            name: 'individual.home.user.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'individual.home.user'
            }
          }
        ]
      },
      {
        path: '/my/contents',
        name: 'individual.home.user.contents',
        redirect: { name: 'individual.home.user.contents.published' },
        component: () => import('@/views/Contents/IndividualContents/IndividualContents'),
        children: [
          {
            path: 'published',
            name: 'individual.home.user.contents.published',
            component: () => import('@/components/home/HomepageUserContents'),
            children: [
              {
                path: 'manage/:contentId?',
                name: 'individual.home.user.contents.published.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'individual.home.user.contents.published'
                }
              },
              {
                path: 'details/:contentId',
                name: 'individual.home.user.contents.published.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.published'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.published.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.published.content.details'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'individual.home.user.contents.published.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.home.user.contents.published'
                }
              }
            ]
          },
          {
            path: 'lists',
            name: 'individual.contents.lists',
            component: () => import('@/components/home/HomepageUserLists'),
            children: [
              {
                path: 'manage/:listId?',
                name: 'individual.contents.lists.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.contents.lists'
                }
              },
              {
                path: 'details/:listId',
                name: 'individual.contents.lists.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'individual.contents.lists'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.contents.lists.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.contents.lists.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'individual.contents.lists.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'individual.contents.lists.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'individual.contents.lists.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'individual.contents.lists.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'manage/:listId?',
                    name: 'individual.contents.lists.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'individual.contents.lists'
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'enrolled',
            name: 'individual.home.user.contents.enrolled',
            component: () => import('@/components/home/HomepageUserEnrollment')
          },
          {
            path: 'favorites',
            name: 'individual.home.user.contents.favorites',
            component: () => import('@/components/home/HomepageUserFavorites'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'individual.home.user.contents.favorites.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'individual.home.user.contents.favorites'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'individual.home.user.contents.favorites.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.favorites'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.favorites.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.favorites.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'individual.home.user.contents.favorites.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.home.user.contents.favorites'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'individual.home.user.contents.favorites.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.favorites'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.favorites.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.favorites.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'individual.home.user.contents.favorites.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.favorites.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'individual.home.user.contents.favorites.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'individual.home.user.contents.favorites.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'individual.home.user.contents.favorites.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.favorites'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'individual.home.user.contents.favorites.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.home.user.contents.favorites'
                }
              }
            ]
          },
          {
            path: 'in-progress',
            name: 'individual.home.user.contents.in.progress',
            component: () => import('@/views/Contents/IndividualContents/IndividualInProgressContents'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'individual.home.user.contents.in.progress.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'home'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'individual.home.user.contents.in.progress.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.in.progress'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.in.progress.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.in.progress.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'individual.home.user.contents.in.progress.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.home.user.contents.in.progress'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'individual.home.user.contents.in.progress.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.in.progress'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.in.progress.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.in.progress.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'individual.home.user.contents.in.progress.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.in.progress.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'individual.home.user.contents.in.progress.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'individual.home.user.contents.in.progress.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'individual.home.user.contents.in.progress.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.in.progress'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'individual.home.user.contents.in.progress.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.home.user.contents.in.progress'
                }
              }
            ]
          },
          {
            path: 'finished',
            name: 'individual.home.user.contents.finished',
            component: () => import('@/views/Contents/IndividualContents/IndividualFinishedContents'),
            children: [
              {
                path: 'content/manage/:contentId?',
                name: 'individual.home.user.contents.finished.content.manage',
                component: () => import('@/components/contents/ModalManageContent'),
                meta: {
                  backRouteName: 'home'
                }
              },
              {
                path: 'contents/:contentId',
                name: 'individual.home.user.contents.finished.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.finished'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.finished.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.finished.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'individual.home.user.contents.finished.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.home.user.contents.finished'
                }
              },
              {
                path: 'contents/pathways/:listId',
                name: 'individual.home.user.contents.finished.lists.details',
                component: () => import('@/components/lists/ModalListDetails'),
                meta: {
                  backRouteName: 'individual.home.user.contents.finished'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.contents.finished.lists.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.finished.lists.details'
                    }
                  },
                  {
                    path: 'content/:contentId',
                    name: 'individual.home.user.contents.finished.lists.details.content.details',
                    component: () => import('@/components/contents/ModalContentDetails'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.finished.lists.details'
                    },
                    children: [
                      {
                        path: 'pathway-association',
                        name: 'individual.home.user.contents.finished.lists.details.content.details.association',
                        component: () => import('@/components/contents/ModalContentListAssociation'),
                        meta: {
                          backRouteName: 'individual.home.user.contents.finished.lists.details.content.details'
                        }
                      }
                    ]
                  },
                  {
                    path: 'contents/pathways/:listId?/manage',
                    name: 'individual.home.user.contents.finished.lists.details.lists.manage',
                    component: () => import('@/components/lists/ModalManageList'),
                    meta: {
                      backRouteName: 'individual.home.user.contents.finished'
                    }
                  }
                ]
              },
              {
                path: ':contentId/pathway-association',
                name: 'individual.home.user.contents.finished.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.home.user.contents.finished'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/lists',
        name: 'individual.home.user.lists',
        component: () => import('@/components/home/HomepageUserLists'),
        children: [
          {
            path: 'manage/:listId?',
            name: 'individual.home.user.lists.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'individual.home.user.lists'
            }
          },
          {
            path: 'details/:listId',
            name: 'individual.home.user.lists.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'individual.home.user.lists'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'individual.home.user.lists.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'individual.home.user.lists.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'individual.home.user.lists.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'individual.home.user.lists.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage/:listId?',
                name: 'individual.home.user.lists.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'individual.home.user.lists'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/my/applications',
    name: 'individual.candidature.index',
    component: () => import('@/views/Candidature'),
    redirect: { name: 'individual.candidature.under.analysis' },
    children: [
      {
        path: '/my/applications/under-analysis',
        name: 'individual.candidature.under.analysis',
        component: () => import('@/views/Candidature/UnderAnalysisCandidatures')
      },
      {
        path: '/my/applications/unfinished',
        name: 'individual.candidature.unfinished',
        component: () => import('@/views/Candidature/NotCompletedCandidatures')
      },
      {
        path: '/my/applications/saved',
        name: 'individual.candidature.saved',
        component: () => import('@/views/Candidature/SavedCandidatures')
      },
      {
        path: '/my/applications/closed',
        name: 'individual.candidature.closed',
        component: () => import('@/views/Candidature/ClosedCandidatures')
      }]
  },
  {
    path: '/my/career',
    name: 'individual.home.user.career',
    component: () => import('@/views/EmptyRouterView')
  },
  {
    path: '/my/discovery/position/:positionId',
    name: 'individual.position.details',
    component: () => import('@/views/PositionView'),
    meta: {
      backRouteName: 'individual.discovery.index'
    }
  },
  {
    path: '/my/discovery/course/:courseId',
    name: 'individual.course.details',
    component: () => import('@/views/CourseView'),
    meta: {
      backRouteName: 'individual.discovery.index'
    }
  },
  {
    name: 'individual.discovery.index',
    path: '/my/discovery',
    component: () => import('@/views/IndividualDiscovery'),
    redirect: { name: 'individual.discovery.index.search', query: { type: 'positions' } },
    children: [
      {
        path: 'list/details/:listId',
        name: 'individual.discovery.index.default.list.details',
        component: () => import('@/components/lists/ModalListDetails'),
        meta: {
          backRouteName: 'individual.discovery.index'
        }
      },
      {
        path: 'content/details/:contentId',
        name: 'individual.discovery.index.default.content.details',
        component: () => import('@/components/contents/ModalContentDetails'),
        meta: {
          backRouteName: 'individual.discovery.index'
        },
        children: [
          {
            path: 'pathway-association',
            name: 'individual.discovery.index.default.content.details.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'individual.discovery.index.default.content.details'
            }
          }
        ]
      },
      {
        path: '/my/discovery/search?type=contents',
        name: 'individual.discovery.index.search.association',
        component: () => import('@/components/contents/ModalContentListAssociation'),
        meta: {
          backRouteName: 'individual.discovery.index.search'
        }
      },
      {
        path: 'search',
        name: 'individual.discovery.index.search',
        component: () => import('@/views/Discovery/DiscoveryFilter'),
        meta: {
          backRouteName: 'individual.discovery.index'
        }
      },
      {
        path: 'contents/:contentId?/manage',
        name: 'individual.discovery.index.content.manage',
        component: () => import('@/components/contents/ModalManageContent'),
        meta: {
          backRouteName: 'individual.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId?/manage',
        name: 'individual.discovery.index.lists.manage',
        component: () => import('@/components/lists/ModalManageList'),
        meta: {
          backRouteName: 'individual.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId',
        name: 'individual.discovery.index.lists.details',
        component: () => import('@/components/lists/ModalListDetails'),
        meta: {
          backRouteName: 'individual.discovery.index'
        },
        children: [
          {
            path: ':contentId',
            name: 'individual.discovery.index.lists.details.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'individual.discovery.index.lists.details'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'individual.discovery.index.lists.details.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'individual.discovery.index.lists.details.content.details'
                }
              }
            ]
          },
          {
            path: 'manage',
            name: 'individual.discovery.index.lists.details.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'individual.discovery.index'
            }
          }
        ]
      }
    ]
  }
]
