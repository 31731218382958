import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/domains'
import VuexPersistence from 'vuex-persist'
import { vuexI18nPlugin } from '@/support/i18n/index'

import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins: [vuexLocal.plugin, vuexI18nPlugin],
  state,
  mutations,
  actions,
  getters
})
