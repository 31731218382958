var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:{ 'mobile': _vm.isMobile && !_vm.isWorkspaceSetup, 'authenticated': _vm.userToken || _vm.$route.meta.registerRoute, 'authentication-mobile': _vm.isMobile && _vm.$route && _vm.$route.name && _vm.$route.name.includes('auth'), 'auth-screen': _vm.$route && _vm.$route.name && _vm.$route.name.includes('auth') },attrs:{"id":"app"}},[(_vm.loading)?_c('div',[_c('Loading')],1):_c('div',[((_vm.applicationLoaded || _vm.$route.name === 'auth.login'))?_c('v-main',[(_vm.getInviteDetails.showMessageInvite)?_c('modal-show-message',{attrs:{"title":_vm.$t(_vm.getInviteDetails.title),"text":_vm.$t(_vm.getInviteDetails.text,
      {
        name: _vm.getInviteDetails.name,
        companyName: _vm.getInviteDetails.companyName,
        inviteEmail: _vm.getInviteDetails.inviteEmail,
        userEmail: _vm.getInviteDetails.userEmail,
        link: 'https://plooral.zendesk.com/hc/pt-br'
      })},on:{"close":_vm.closeModal}}):_vm._e(),(_vm.getHelpMsg)?_c('feedback',{attrs:{"msg":_vm.getHelpMsg}}):_vm._e(),(_vm.getFeedbackMsg)?_c('snackbar',{attrs:{"values":_vm.getFeedbackMsg}}):_vm._e(),(_vm.verifyTos)?_c('div',[(_vm.isAllowedLoading && !_vm.routerMeta.hideMenu)?_c('header-menu'):_vm._e()],1):_vm._e(),_c('router-view')],1):_vm._e()],1),(_vm.$route && _vm.$route.name && _vm.$route.name.includes('auth'))?_c('help-center-btn'):_vm._e(),(_vm.$route && _vm.$route.name && _vm.$route.name.includes('candidature'))?_c('app-banner'):_vm._e(),_c('modal-change-workspace',{key:_vm.selectedWorkspace && _vm.selectedWorkspace.subdomain})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }