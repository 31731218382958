export default [
  {
    name: 'discovery.index',
    path: '/discovery',
    component: () => import('@/views/Discovery'),
    children: [
      {
        path: 'content/details/:contentId',
        name: 'discovery.index.content.details',
        component: () => import('@/components/contents/ModalContentDetails'),
        meta: {
          backRouteName: 'discovery.index'
        },
        children: [
          {
            path: 'association',
            name: 'discovery.index.content.details.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'discovery.index.content.details'
            }
          }
        ]
      },
      {
        path: 'content/manage/:contentId?',
        name: 'discovery.index.content.manage',
        component: () => import('@/components/contents/ModalManageContent'),
        meta: {
          backRouteName: 'discovery.index'
        }
      },
      {
        path: 'list/manage/:listId?',
        name: 'discovery.index.lists.manage',
        component: () => import('@/components/lists/ModalManageList'),
        meta: {
          backRouteName: 'discovery.index'
        }
      },
      {
        path: ':contentId/association',
        name: 'discovery.index.association',
        component: () => import('@/components/contents/ModalContentListAssociation'),
        meta: {
          backRouteName: 'discovery.index'
        }
      },
      {
        path: 'list/details/:listId',
        name: 'discovery.index.lists.details',
        component: () => import('@/components/lists/ModalListDetails'),
        meta: {
          backRouteName: 'discovery.index'
        },
        children: [
          {
            path: 'content/:contentId',
            name: 'discovery.index.lists.details.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'discovery.index.lists.details'
            },
            children: [
              {
                path: 'association',
                name: 'discovery.index.lists.details.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'discovery.index.lists.details.content.details'
                }
              }
            ]
          },
          {
            path: 'manage',
            name: 'discovery.index.lists.details.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'discovery.index.lists.details'
            }
          }
        ]
      }
    ]
  }
]
