import 'url-search-params-polyfill'
import qs from 'qs'

export const parse = payload => {
  const params = new URLSearchParams() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]
    if (value instanceof Array) {
      value.forEach((arrValue) => {
        params.append(key + '[]', arrValue)
      })
    } else if (value instanceof Object) {
      const childrenKeys = Object.keys(value)
      childrenKeys.forEach(child => {
        params.append(keys[i] + '.' + child, value[child])
      })
    } else {
      value = String(value)

      if (value !== null && value !== 'null' && value !== 'undefined' && value !== undefined && value.length) {
        params.append(key, value)
      }
    }
  }

  return params
}

export const parseToFormData = payload => {
  const params = new FormData() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]
    value = String(value)

    if (value !== null && value !== 'undefined' && value !== undefined && value.length) {
      params.append(key, value)
    }
  }

  return params
}

export const parseToFormDataWithArray = payload => {
  const params = new FormData() // eslint-disable-line no-undef

  if (typeof payload !== 'object') return null

  const keys = Object.keys(payload)
  const length = keys.length

  for (let i = 0; i < length; i += 1) {
    let value = payload[keys[i]]
    const key = keys[i]
    value = Array.isArray(value) ? value : String(value)

    if (value !== null && value !== 'null' && value !== 'undefined' && value !== undefined && (value.length || typeof value === 'string')) {
      if (Array.isArray(value)) {
        value.forEach((element, index) => {
          if (typeof element === 'object') {
            const elKeys = Object.keys(element)
            elKeys.forEach(elKey => {
              params.append(key + '[' + index + '][' + elKey + ']', element[elKey])
            })
          } else {
            params.append(key + '[]', element)
          }
        })
      } else {
        params.append(key, value)
      }
    }
  }
  return params
}

export const createQuery = (queryParams = {}) => {
  const params = {
    query: null,
    filter: null,
    order: null,
    page: 1,
    sortBy: null,
    limit: null
  }
  Object.keys(params).forEach((key) => {
    params[key] = queryParams[key] || null
  })
  return qs.stringify(params)
}
