
<script>
export default {
  name: 'Loading',
  props: {
    text: {
      type: String,
      default: ''
    },
    showText: {
      type: Boolean,
      default: false
    },
    showSpinner: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '30'
    },
    width: {
      type: String,
      default: '3'
    },
    color: {
      type: String,
      default: '#1200D3'
    }
  }
}
</script>
<template>
  <div class="loading-wrapper">
    <v-progress-circular
      v-if="showSpinner"
      indeterminate
      :color="color"
      :width="width"
      :size="size"
    ></v-progress-circular>
    <p v-if="showText">{{ $t(text) }}</p>
  </div>
</template>
<style lang="scss">
.loading-wrapper {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
