import { http, hireHttp } from '@/support/axios'
import { parse, createQuery } from '@/support/payloadParser'
import qs from 'qs'

export default {
  state: {
    discoveryList: [],
    positionAndCourseSuggestions: [],
    filteredListLength: 0,
    positionsCategory: [],
    filteredList: [],
    discoveryPagination: null,
    accessFromDiscovery: false,
    shouldCachingFilters: false,
    coursesSegments: [],
    coursesProviders: []
  },
  getters: {
    getDiscoveryList: (state) => state.discoveryList,
    getPositionAndCourseSuggestions: (state) => state.positionAndCourseSuggestions,
    getMostViewedCourses: (state) => state.mostViewedCourses,
    getPositionsCategory: (state) => state.positionsCategory,
    getDiscoveryPagination: state => state.discoveryPagination,
    getAccessFromDiscovery: state => state.accessFromDiscovery,
    getShouldCachingFilters: ({ shouldCachingFilters }) => shouldCachingFilters,
    getCoursesSegments: state => state.coursesSegments,
    getCoursesProviders: state => state.coursesProviders
  },
  mutations: {
    setDiscoveryList (state, payload) {
      state.discoveryList = payload
    },
    updatePositionsCategory (state, positionCategory) {
      state.positionsCategory = positionCategory
    },
    setDiscoveryPagination (state, payload) {
      state.discoveryPagination = payload
    },
    setAccessFromDiscovery (state, payload) {
      state.accessFromDiscovery = payload
    },
    setShouldCachingFilters (state, payload) {
      state.shouldCachingFilters = payload
    },
    setCoursesSegments (state, payload) {
      state.coursesSegments = payload
    },
    setCoursesProviders (state, payload) {
      state.coursesProviders = payload
    }
  },
  actions: {
    attemptSetShouldCachingFilters ({ commit }, payload) {
      commit('setShouldCachingFilters', payload)
    },
    attemptSetAcessContent ({ commit }, payload) {
      commit('setAccessFromDiscovery', payload)
    },
    attemptGetDiscoveryList ({ commit }, filter) {
      if (!filter.filter.categories) {
        filter.filter.categories = ['content', 'list']
      }
      if (filter.filter.duration) {
        filter.filter.duration = JSON.parse(filter.filter.duration)
      }
      const query = createQuery(filter)
      return http.get(`v1/discovery?${query}`).then(({ data }) => {
        commit('setDiscoveryList', data.data)
        return data
      }).catch(() => {
        return { error: true }
      })
    },
    attemptSubmitHitContent (_, id) {
      http.patch(`v1/contents/${id}/hits`)
    },
    attemptSubmitHitList (_, id) {
      http.patch(`v1/lists/${id}/hits`)
    },
    attemptSubmitHitProfile (_, id) {
      http.patch(`v1/workspace/${id}/hits`)
    },
    attemptGetProfileLocations (_, name) {
      const query = { filter: { location: name } }
      return getLocation(query)
    },
    attemptGetBusinessLocations (_, name) {
      const query = { filter: { location: name, isBusiness: true } }
      return getLocation(query)
    },
    attemptGetMostViewedCourses (_, { aggregator }) {
      return hireHttp.get(`v1/courses/suggestions${aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data.course
      })
    },
    attemptGetNewPositionsPublisheds (_, { user, aggregator }) {
      const region = user.region
      const language = user.language
      const payload = {
        salary: {
          minimum: 0,
          allowUnspecified: true
        },
        locale: region ? (region === 'BRA' ? 'pt-BR' : 'en-US') : (language === 'pt' ? 'pt-BR' : 'en-US'),
        lang: language,
        length: 15,
        aggregator: this.getCompanyDomainAndWorkspace
      }
      if (aggregator.selectedWorkspace === 'aggregator') payload.aggregator = aggregator.companyDomain
      return hireHttp.get(`v1/suggestions?${parse(payload)}`).then(({ data }) => {
        return data
      })
    },
    attemptGetPositionsCategory ({ commit }, language = 'pt') {
      const iconList = [
        { title: ['Administração e Negócios', 'Business Management and Administration'], icon: 'mdi-home' },
        { title: ['Agricultura, Alimentos e Recursos Naturais', 'Agriculture, Food and Natural Resources'], icon: 'mdi-leaf' },
        { title: ['Arquitetura e Construção', 'Architecture and Construction'], icon: 'mdi-hammer-wrench' },
        { title: ['Artes e Design', 'Arts and Design'], icon: 'mdi-palette' },
        { title: ['Atendimento ao Cliente', 'Customer Services'], icon: 'mdi-face-agent' },
        { title: ['Engenharia e Ciências', 'Science, Technology, Engineering and Mathematics'], icon: 'mdi-hammer-wrench' },
        { title: ['Ensino e Educação', 'Education and Training'], icon: 'mdi-chair-school' },
        { title: ['Finanças', 'Finance'], icon: 'mdi-finance' },
        { title: ['Gastronomia', 'Gastronomy'], icon: 'mdi-food' },
        { title: ['Hotelaria e Turismo', 'Hospitality and Tourism'], icon: 'mdi-bed' },
        { title: ['Jurídico', 'Law, Public Safety, Corrections and Security'], icon: 'mdi-scale-balance' },
        { title: ['Logistica', 'Transportation, Distribution and Logistics'], icon: 'mdi-dolly' },
        { title: ['Manufatura Industrial', 'Manufacturing'], icon: 'mdi-factory' },
        { title: ['Marketing e Vendas', 'Marketing and Sales'], icon: 'mdi-shopping' },
        { title: ['Mídia e Comunicação', 'Audio/Video Technology and Communications'], icon: 'mdi-human-greeting-proximity' },
        { title: ['Outros', 'Others'], icon: 'mdi-package-variant' },
        { title: ['Saúde', 'Health Science'], icon: 'mdi-doctor' },
        { title: ['Serviços Gerais', 'General Services'], icon: 'mdi-hammer-screwdriver' },
        { title: ['Tecnologia', 'Information Technology'], icon: 'mdi-cellphone-link' },
        { title: ['Voluntários COVID-19', 'Volunteers COVID-19'], icon: 'mdi-face-mask' }
      ]
      return hireHttp.get(`v1/categories?${parse({ lang: language })}`).then((categories) => {
        const categoryFiltered = categories.data.map(category => ({
          id: category.id,
          title: category.name,
          icon: iconList.filter(icon => icon.title.includes(category.name))[0].icon,
          type: 'icon-card'
        }))
        commit('updatePositionsCategory', categoryFiltered)
        return categoryFiltered
      })
    },
    attempGetCourseProviders ({ commit }, { aggregator }) {
      const { workSpaceType, CompanyDomain } = aggregator
      return hireHttp.get(`v1/courses/providers${workSpaceType === 'aggregator' ? `?aggregator=${CompanyDomain}` : ''}`).then(({ data }) => {
        commit('setCoursesProviders', data.providers)
        return data.providers
      })
    },
    attempGetCourseSegments ({ commit }, { aggregator }) {
      const { workSpaceType, CompanyDomain } = aggregator
      return hireHttp.get(`v1/courses/segments${workSpaceType === 'aggregator' ? `?aggregator=${CompanyDomain}` : ''}`).then(({ data }) => {
        commit('setCoursesSegments', data.segments)
        return data.segments
      })
    },
    attempGetSearchResultSample ({ commit }, { searchParameters }) {
      const getPayloadLocale = (params) => {
        const region = params.user.region
        const language = params.user.kanguage

        if (params.aggregator.CompanyDomain === 'empregosagro') return 'pt-BR'
        return region ? (region === 'BRA' ? 'pt-BR' : 'en-US') : (language === 'pt' ? 'pt-BR' : 'en-US')
      }
      if (searchParameters.type === 'positions') {
        const city = JSON.parse(searchParameters.city)
        const state = JSON.parse(searchParameters.state)
        const language = searchParameters.user.language

        const payload = {
          text: searchParameters.search,
          salary: {
            minimum: searchParameters.remuneration,
            allowUnspecified: searchParameters.undefinedSalary
          },
          categoryList: searchParameters.category,
          locationList: city ? city[0].id : null,
          showOnlyPositionsForDisabled: searchParameters.exclusivePCD,
          locationStateList: state ? state[0].id : null,
          typeWork: Array.isArray(searchParameters.contractType) ? searchParameters.contractType.join() : searchParameters.contractType,
          workPCD: null,
          workTelecommute: searchParameters.remoteWorkOnly ? true : null,
          lang: language,
          locale: getPayloadLocale(searchParameters),
          // locale: searchParameters.workplace
          aggregator: searchParameters.aggregator.CompanyDomain
        }

        return hireHttp.get(`v1/single_search/run?${parse(payload)}`).then(({ data }) => {
          return data
        })
      } else {
        const payload = {
          name: searchParameters.search,
          modality: searchParameters.modality,
          workload: searchParameters.workload,
          price: searchParameters.pricing,
          segment: searchParameters.segment,
          provider: searchParameters.institute,
          page: 1
        }
        return hireHttp.post(`v1/courses/search${searchParameters.aggregator.workSpaceType === 'aggregator' ? `?aggregator=${searchParameters.aggregator.CompanyDomain}` : ''}`, payload).then(({ data }) => {
          return data.course
        })
      }
    },
    attempGetCity (_, { country, input }) {
      const payload = {
        country_code: country,
        query: input
      }
      return hireHttp.get(`v1/cities?${parse(payload)}`).then(({ data }) => {
        return data
      })
    },
    attempGetState (_, { country, input }) {
      const payload = {
        country_code: country,
        query: input
      }
      return hireHttp.get(`v1/states?${parse(payload)}`).then(({ data }) => {
        return data
      })
    }
  }
}

function getLocation (query) {
  const parsedQuery = qs.stringify(query)
  return http.get(`v1/discovery/location?${parsedQuery}`).then(({ data }) => {
    return data
  })
}
