export default [
  {
    path: '/p/:companyDomain',
    name: 'aggregator.home',
    component: () => import('@/views/NewHome'),
    redirect: { name: 'aggregator.home.user' },
    children: [
      {
        path: '/p/:companyDomain',
        name: 'aggregator.home.user',
        component: () => import('@/components/home/index/homepage-aggregators'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'aggregator.home.user.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home'
            }
          },
          {
            path: 'contents/pathways/:listId?/manage',
            name: 'aggregator.home.user.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'aggregator.home.user'
            }
          },
          {
            path: 'contents/pathways/:listId',
            name: 'aggregator.home.user.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'aggregator.home.user'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'aggregator.home.user.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'aggregator.home.user.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'aggregator.home.user.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'aggregator.home.user.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'aggregator.home.user.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'aggregator.home.user'
                }
              }
            ]
          },
          {
            path: ':contentId/pathway-association',
            name: 'aggregator.home.user.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'aggregator.home.user'
            }
          }
        ]
      },
      {
        path: '/favorites',
        name: 'aggregator.home.user.favorites',
        component: () => import('@/components/home/HomepageUserFavorites'),
        children: [
          {
            path: '/content/manage/:contentId?',
            name: 'aggregator.home.user.favorites.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'home'
            }
          },
          {
            path: 'contents/:contentId',
            name: 'aggregator.home.user.favorites.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'aggregator.home.user.favorites'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'aggregator.home.user.favorites.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'aggregator.home.user.favorites.content.details'
                }
              }
            ]
          },
          {
            path: 'contents/pathways/:listId?/manage',
            name: 'aggregator.home.user.favorites.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'aggregator.home.user.favorites'
            }
          },
          {
            path: 'contents/pathways/:listId',
            name: 'aggregator.home.user.favorites.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'aggregator.home.user.favorites'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'aggregator.home.user.favorites.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'aggregator.home.user.favorites.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'aggregator.home.user.favorites.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'aggregator.home.user.favorites.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'contents/pathways/:listId?/manage',
                name: 'aggregator.home.user.favorites.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'aggregator.home.user.favorites'
                }
              }
            ]
          },
          {
            path: ':contentId/pathway-association',
            name: 'aggregator.home.user.favorites.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'aggregator.home.user.favorites'
            }
          }
        ]
      },
      {
        path: '/p/:companyDomain/contents',
        name: 'aggregator.home.user.contents',
        component: () => import('@/components/contents/NewContentsList'),
        meta: {
          hideList: true
        },
        children: [
          {
            path: 'manage/:contentId?',
            name: 'aggregator.home.user.contents.content.manage',
            component: () => import('@/components/contents/ModalManageContent'),
            meta: {
              backRouteName: 'aggregator.home.user.contents'
            }
          },
          {
            path: 'course/:courseId',
            name: 'aggregator.home.user.contents.course.details',
            component: () => import('@/views/CourseView'),
            meta: {
              backRouteName: 'aggregator.home.user.contents'
            }
          },
          {
            path: 'position/:positionId',
            name: 'aggregator.home.user.contents.position.details',
            component: () => import('@/components/positions/PositionDetails'),
            meta: {
              backRouteName: 'aggregator.home.user.contents'
            }
          },
          {
            path: 'details/:contentId',
            name: 'aggregator.home.user.contents.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'aggregator.home.user.contents'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'aggregator.home.user.contents.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'aggregator.home.user.contents.content.details'
                }
              }
            ]
          },
          {
            path: ':contentId/pathway-association',
            name: 'aggregator.home.user.contents.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'aggregator.home.user.contents'
            }
          }
        ]
      },
      {
        path: '/lists',
        name: 'aggregator.home.user.lists',
        component: () => import('@/components/home/HomepageUserLists'),
        children: [
          {
            path: 'manage/:listId?',
            name: 'aggregator.home.user.lists.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'aggregator.home.user.lists'
            }
          },
          {
            path: 'details/:listId',
            name: 'aggregator.home.user.lists.lists.details',
            component: () => import('@/components/lists/ModalListDetails'),
            meta: {
              backRouteName: 'aggregator.home.user.lists'
            },
            children: [
              {
                path: 'content/:contentId',
                name: 'aggregator.home.user.lists.lists.details.content.details',
                component: () => import('@/components/contents/ModalContentDetails'),
                meta: {
                  backRouteName: 'aggregator.home.user.lists.lists.details'
                },
                children: [
                  {
                    path: 'pathway-association',
                    name: 'aggregator.home.user.lists.lists.details.content.details.association',
                    component: () => import('@/components/contents/ModalContentListAssociation'),
                    meta: {
                      backRouteName: 'aggregator.home.user.lists.lists.details.content.details'
                    }
                  }
                ]
              },
              {
                path: 'manage/:listId?',
                name: 'aggregator.home.user.lists.lists.details.lists.manage',
                component: () => import('@/components/lists/ModalManageList'),
                meta: {
                  backRouteName: 'aggregator.home.user.lists'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/members',
        name: 'aggregator.home.user.members',
        component: () => import('@/components/home/HomepageUserMembers'),
        children: [
          {
            path: '/members/list',
            name: 'aggregator.home.user.members.list',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabMember')
          },
          {
            path: '/members/invites',
            name: 'aggregator.home.user.members.invites',
            component: () => import('@/components/workspace/businessWorkspace/WorkspaceManagement/MemberSubmenuTabInvite')
          }
        ]
      },
      {
        path: 'customizations',
        name: 'aggregator.home.user.customization',
        component: () => import('@/components/home/HomepageUserCustomization')
      },
      {
        path: 'management',
        name: 'aggregator.home.user.management',
        component: () => import('@/components/home/HomepageUserManagement')
      }
    ]
  },
  {
    path: '/p/:companyDomain/applications',
    name: 'aggregator.candidature.index',
    redirect: { name: 'aggregator.candidature.under.analysis' },
    component: () => import('@/views/CandidatureCNA'),
    children: [
      {
        path: '/p/:companyDomain/applications/unfinished',
        name: 'aggregator.candidature.unfinished',
        component: () => import('@/views/Candidature/NotCompletedCandidatures')
      },
      {
        path: '/p/:companyDomain/applications/under-analysis',
        name: 'aggregator.candidature.under.analysis',
        component: () => import('@/views/Candidature/UnderAnalysisCandidatures')
      },
      {
        path: '/p/:companyDomain/applications/saved',
        name: 'aggregator.candidature.saved',
        component: () => import('@/views/Candidature/SavedCandidatures')
      },
      {
        path: '/p/:companyDomain/applications/closed',
        name: 'aggregator.candidature.closed',
        component: () => import('@/views/Candidature/ClosedCandidatures')
      }
    ]
  },
  {
    path: '/p/:companyDomain/discovery/position/:positionId',
    name: 'aggregator.position.details',
    component: () => import('@/components/positions/PositionDetails'),
    meta: {
      backRouteName: 'aggregator.discovery.index'
    }
  },
  {
    path: '/p/:companyDomain/discovery/course/:courseId',
    name: 'aggregator.course.details',
    component: () => import('@/views/CourseView'),
    meta: {
      backRouteName: 'aggregator.discovery.index'
    }
  },
  {
    name: 'aggregator.discovery.index',
    path: '/p/:companyDomain/discovery',
    redirect: { name: 'aggregator.discovery.positions.default' },
    component: () => import('@/views/Discovery/'),
    children: [
      {
        path: 'positions',
        name: 'aggregator.discovery.positions.default',
        component: () => import('@/views/Discovery/Aggregators/DiscoveryPositionsDefault'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        }
      },
      {
        path: 'courses',
        name: 'aggregator.discovery.courses.default',
        component: () => import('@/views/Discovery/Aggregators/DiscoveryCoursesDefault'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        }
      },
      {
        path: 'search',
        name: 'aggregator.discovery.index.search',
        component: () => import('@/views/Discovery/DiscoveryFilter'),
        meta: {
          backRouteName: 'aggregator.discovery.index',
          hideSearchFilter: true
        }
      },
      {
        path: 'contents/:contentId',
        name: 'aggregator.discovery.index.content.details',
        component: () => import('@/components/contents/ModalContentDetails'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        },
        children: [
          {
            path: 'pathway-association',
            name: 'aggregator.discovery.index.content.details.association',
            component: () => import('@/components/contents/ModalContentListAssociation'),
            meta: {
              backRouteName: 'aggregator.discovery.index.content.details'
            }
          }
        ]
      },
      {
        path: 'contents/:contentId?/manage',
        name: 'aggregator.discovery.index.content.manage',
        component: () => import('@/components/contents/ModalManageContent'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId?/manage',
        name: 'aggregator.discovery.index.lists.manage',
        component: () => import('@/components/lists/ModalManageList'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        }
      },
      {
        path: 'contents/pathways/:listId',
        name: 'aggregator.discovery.index.lists.details',
        component: () => import('@/components/lists/ModalListDetails'),
        meta: {
          backRouteName: 'aggregator.discovery.index'
        },
        children: [
          {
            path: ':contentId',
            name: 'aggregator.discovery.index.lists.details.content.details',
            component: () => import('@/components/contents/ModalContentDetails'),
            meta: {
              backRouteName: 'aggregator.discovery.index.lists.details'
            },
            children: [
              {
                path: 'pathway-association',
                name: 'aggregator.discovery.index.lists.details.content.details.association',
                component: () => import('@/components/contents/ModalContentListAssociation'),
                meta: {
                  backRouteName: 'aggregator.discovery.index.lists.details.content.details'
                }
              }
            ]
          },
          {
            path: 'manage',
            name: 'aggregator.discovery.index.lists.details.lists.manage',
            component: () => import('@/components/lists/ModalManageList'),
            meta: {
              backRouteName: 'aggregator.discovery.index'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/p/:companyDomain/internal-mobility',
    name: 'aggregator.internal.positions',
    component: () => import('@/views/EmptyRouterView')
  },
  {
    path: '/p/:companyDomain/reports',
    name: 'aggregator.reports',
    component: () => import('@/views/EmptyRouterView')
  }
]
