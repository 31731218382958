export default [
  {
    path: '/account',
    name: 'settings.index',
    redirect: { name: 'profile.form' },
    component: () => import('@/views/Settings'),
    children: [
      {
        path: 'profile',
        name: 'settings.profile.form',
        component: () => import('@/components/settings/NewProfileForm')
      },
      {
        path: 'skills',
        name: 'settings.skills.form',
        component: () => import('@/components/settings/NewSkillsForm')
      },
      {
        path: 'communication',
        name: 'settings.communication.form',
        component: () => import('@/components/settings/CommunicationForm')
      },
      {
        path: 'settings',
        name: 'settings.form',
        component: () => import('@/components/settings/SettingsForm')
      },
      {
        path: 'terms',
        name: 'settings.terms.form',
        component: () => import('@/components/settings/TermsForm')
      }
    ]
  },
  {
    name: 'reactive.account',
    path: '/reactive/account',
    component: () => import('@/views/ReactiveAccount')
  },
  {
    path: '/invite/:inviteId',
    name: 'invite.check',
    component: () => import('@/views/InviteCheck'),
    meta: {
      hideMenu: true
    }
  },
  {
    path: '/',
    name: 'workspace.selection.index',
    component: () => import('@/views/WorkspaceSelection'),
    meta: {
      hideMenu: true
    }
  }
]
